import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Select, SearchInput, ResponsiveSelect, COLORS } from '@protonradio/proton-ui';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import useCookie from 'react-use-cookie';
import { discoveryModeQueryParams, useDiscoveryMode } from './DiscoveryModeContext';
import { hideIntercomLauncher, showIntercomLauncher } from 'config/intercom';
import { BREAKPOINTS } from 'config/constants/styles';

const PREFERRED_ENTITY_COOKIE = 'preferred_discovery_mode_entity';

const BaseSelectWrapper = styled.div`
  min-width: 200px;
  button {
    min-height: 40px;
    padding: 1rem 1.5rem;
  }

  @media (max-width: ${BREAKPOINTS.MD}px) {
    max-width: 200px;
  }
`;

const BaseSearchWrapper = styled.div`
  width: 100%;
  min-width: 340px;

  @media (max-width: ${BREAKPOINTS.MD}px) {
    width: 100%;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (max-width: ${BREAKPOINTS.MD}px) {
    flex-direction: column;
    background-color: ${COLORS.GRAYSCALE.GRAY_SUPER_LIGHT};
    border-bottom: 1px solid #dddddd;
    overflow: hidden;
    position: sticky;
    top: 44px;
    z-index: 1;
    padding: 1rem 10px 1rem 10px;
    margin: 0 -20px 0 -20px;
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    padding: 1rem 10px 1rem 10px;
    margin: 0 -10px 0 -10px;
  }
`;

const DiscoveryModeFilterControls = () => {
  const { loading, entities, eligibleTracklist, enabledTracklist } = useDiscoveryMode();

  const { q: searchQuery, updateParams } = useUrlQueryParams(discoveryModeQueryParams);
  const defaultEntity = entities.find(e => e.isOptedIn)?.name || entities[0]?.name;
  const [preferredEntity, setPreferredEntity] = useCookie(
    PREFERRED_ENTITY_COOKIE,
    defaultEntity
  );

  const setSelectedEntity = useCallback((entity: string) => {
    setPreferredEntity(entity, {
      SameSite: 'Lax',
      domain: 'protonradio.com'
    });
    updateParams({ e: entity }, 'replaceIn');
  }, []);

  useEffect(() => {
    if (preferredEntity) {
      updateParams({ e: preferredEntity }, 'replaceIn');
      return;
    }

    setSelectedEntity(defaultEntity);
  }, [defaultEntity, entities, preferredEntity, setPreferredEntity, updateParams]);

  const isEnabledRoute = window.location.pathname.includes('enabled');

  const options = entities.map(entity => ({
    label: entity.name,
    value: entity.name
  }));

  const isLoading = !searchQuery && loading;

  return (
    <StyledFlex>
      <BaseSearchWrapper>
        <SearchInput
          value={searchQuery as string}
          onChange={value => updateParams({ q: value }, 'replaceIn')}
          onClear={() => updateParams({ q: undefined }, 'replaceIn')}
          name="discovery-mode-track-search"
          placeholder={
            isLoading
              ? 'Loading...'
              : isEnabledRoute
                ? `Search ${enabledTracklist.length} Enabled Tracks`
                : `Search ${eligibleTracklist.length} Eligible Tracks`
          }
        />
      </BaseSearchWrapper>
      <BaseSelectWrapper>
        <ResponsiveSelect.Menu
          name="discovery-mode-artist-filter"
          items={options}
          selectedKey={preferredEntity}
          onOpen={hideIntercomLauncher}
          onClose={showIntercomLauncher}
          onSelectionChange={setSelectedEntity}
        >
          {item => <Select.Option key={item.value}>{item.label}</Select.Option>}
        </ResponsiveSelect.Menu>
      </BaseSelectWrapper>
    </StyledFlex>
  );
};

export default DiscoveryModeFilterControls;
