import React, { createContext, useContext, ReactNode, useMemo } from 'react';

import useScheduledQueue from './useScheduledQueue';

import {
  DiscoveryModeConfigsQuery,
  DiscoveryModeStateQuery,
  EligibleTracksFragment
} from 'graphql/queries/discoveryMode';
import { DiscoveryModeQuery, EligibleTracksFieldsFragment } from 'gql/graphql';
import { getFragmentData } from 'gql';
import { StringParam } from 'use-query-params';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { useQueryWithAlert } from 'hooks/graphql/useQueryWithAlert';
import { mapDiscoveryModeEntities } from './helpers';

export const discoveryModeQueryParams = {
  q: { type: StringParam, default: '' }, // search query
  e: { type: StringParam, default: '' }, // selected entity name
  i: { type: StringParam, default: '' } // inviter entity name
};

export type DiscoveryModeEntity = {
  id: string;
  name: string;
  isOptedIn: boolean;
  type: 'artist' | 'label';
  imageUrl: string;
};

interface DiscoveryModeContextType {
  /* No cached data exists, fetching initial data */
  loading: boolean;

  /* Error fetching DiscoveryMode data */
  error?: Error;

  /* DiscoveryMode data */
  discoveryMode?: DiscoveryModeQuery['discoveryMode'];

  /* Eligible tracks for the upcoming campaign */
  eligibleTracklist: EligibleTracksFieldsFragment[];

  /* Enabled tracks for the currently running campaign */
  enabledTracklist: EligibleTracksFieldsFragment[];

  /* Scheduled queue for the current campaign */
  scheduledQueue: ReturnType<typeof useScheduledQueue>;

  /* Entities (artists/labels) that the user has opted in to */
  entities: DiscoveryModeEntity[];

  /* Selected entity (artist/label) */
  selectedEntity?: DiscoveryModeEntity;

  /* User level feature flag for seen onboarding */
  hasSeenOnboarding: boolean;
}

const DiscoveryModeContext = createContext<DiscoveryModeContextType | undefined>(
  undefined
);

export const useDiscoveryMode = () => {
  const context = useContext(DiscoveryModeContext);
  if (!context) {
    throw new Error('useDiscoveryMode must be used within a DiscoveryModeProvider');
  }
  return context;
};

export const DiscoveryModeProvider = ({ children }: { children: ReactNode }) => {
  const { e: selectedEntity, q: searchQuery } = useUrlQueryParams(
    discoveryModeQueryParams
  );

  const [{ data: viewerData, error: viewerError }] = useQueryWithAlert({
    query: DiscoveryModeConfigsQuery
  });

  const entities = mapDiscoveryModeEntities(viewerData?.viewer);
  const foundEntity = entities?.find(entity => entity.name === selectedEntity);
  const userHasSeenOnboarding =
    viewerData?.viewer?.configs?.hasSeenDiscoveryModeOnboarding ?? false;

  const [{ data, error: dmError }] = useQueryWithAlert({
    query: DiscoveryModeStateQuery,
    variables: {
      filter: {
        ...(foundEntity?.type === 'artist'
          ? { artistNames: { cont: selectedEntity } }
          : { labelName: { cont: selectedEntity } }),
        trackName: { cont: searchQuery }
      }
    },
    requestPolicy: 'cache-and-network'
  });

  const discoveryMode = data?.discoveryMode;
  const eligibleTracks = data?.discoveryMode?.upcomingCampaign?.eligibleTracks;
  const eligibleTracklist = getFragmentData(EligibleTracksFragment, eligibleTracks) || [];

  const enabledTracks = data?.discoveryMode?.runningCampaign?.eligibleTracks;
  const enabledTracklist = getFragmentData(EligibleTracksFragment, enabledTracks) || [];

  const scheduledQueue = useScheduledQueue(eligibleTracklist);

  return (
    <DiscoveryModeContext.Provider
      value={{
        loading: !Boolean(data && viewerData),
        error: dmError || viewerError,
        discoveryMode,
        eligibleTracklist,
        enabledTracklist,
        scheduledQueue,
        entities,
        selectedEntity: foundEntity,
        hasSeenOnboarding: userHasSeenOnboarding
      }}
    >
      {children}
    </DiscoveryModeContext.Provider>
  );
};
