import {
  DiscoveryModeEnableDisableTrackInput,
  EligibleTracksFieldsFragment
} from 'gql/graphql';
import { EnableDisableTrackMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { useAppDispatch } from 'hooks/redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { showAlert } from 'redux/actions/ui';
import { CombinedError } from 'urql';

type ScheduledTrackState = 'adding' | 'removing' | boolean;
type ScheduledTrackData = {
  id: string;
  img: string;
  title: string;
  artist: string;
  loading: ScheduledTrackState;
  error?: CombinedError | null;
};
type ScheduledTrackLoading = {
  id: string;
  loading: ScheduledTrackState;
  error?: CombinedError | null;
};
type ScheduledTrack = ScheduledTrackData | ScheduledTrackLoading;
export type ScheduledTracksCart = ScheduledTrack[] & { length: 0 | 1 | 2 | 3 };

const isTrackLoaded = (track: ScheduledTrack): track is ScheduledTrackData =>
  track.loading === false;

const transformScheduledTrack = (
  track: Pick<
    EligibleTracksFieldsFragment['track'],
    'id' | 'title' | 'version' | 'originalArtists' | 'release'
  >
): ScheduledTrackData => ({
  id: track.id,
  img: track.release?.coverArt?.url || '',
  title: track.title || '',
  artist: track.originalArtists[0].name || '',
  loading: false
});

const useScheduledQueue = (eligibleTracklist: EligibleTracksFieldsFragment[]) => {
  useEffect(() => {
    const scheduled = eligibleTracklist
      .filter(track => track.enabled)
      .map(t => transformScheduledTrack(t.track))
      .slice(0, 3);
    setQueue(scheduled as ScheduledTracksCart);
  }, [
    // Only depend on the relevant data from enabled tracks
    eligibleTracklist
      .filter(t => t.enabled)
      .map(t => t.id)
      .join(',')
  ]);

  const [queue, setQueue] = useState<ScheduledTracksCart>([]);
  const [, enableDisableTrack] = useMutationWithAlert(EnableDisableTrackMutation);
  const dispatch = useAppDispatch();

  const addToQueue = useCallback((pendingTrack: ScheduledTrackLoading) => {
    setQueue(prevTracks => [...prevTracks, pendingTrack] as ScheduledTracksCart);
  }, []);

  const removeFromQueue = useCallback((trackId: string) => {
    setQueue(
      prevTracks =>
        prevTracks.filter(
          t => isTrackLoaded(t) && t.id !== trackId
        ) as ScheduledTracksCart
    );
  }, []);

  const updateInQueue = useCallback(
    (oldTrack: ScheduledTrack, newTrack: ScheduledTrack) => {
      setQueue(
        prevTracks =>
          prevTracks.map(t =>
            t.id === oldTrack.id ? { ...t, ...newTrack } : t
          ) as ScheduledTracksCart
      );
    },
    []
  );

  const addTrack = useCallback(
    async (input: DiscoveryModeEnableDisableTrackInput) => {
      const pendingTrack: ScheduledTrackLoading = {
        loading: 'adding',
        error: null,
        id: input.trackId
      };

      addToQueue(pendingTrack);

      const { data, error, hasErrors } = await enableDisableTrack({
        input: { ...input, enabled: true }
      });

      if (hasErrors || error) {
        removeFromQueue(input.trackId);
        return;
      }

      const track = data?.discoveryModeEnableDisableTrack?.discoveryModeTrack?.track;
      if (track) {
        const scheduledTrack = transformScheduledTrack(track);
        updateInQueue(pendingTrack, scheduledTrack);
      }
    },
    [addToQueue, updateInQueue, enableDisableTrack, removeFromQueue]
  );

  const removeTrack = useCallback(
    async (input: DiscoveryModeEnableDisableTrackInput) => {
      const trackToRemove = queue.find(t => isTrackLoaded(t) && t.id === input.trackId);
      if (!trackToRemove) {
        dispatch(showAlert('Error removing track, try again later'));
        return;
      }

      updateInQueue(trackToRemove, {
        ...trackToRemove,
        loading: 'removing',
        error: null
      });

      const { data, error, hasErrors } = await enableDisableTrack({
        input: { ...input, enabled: false }
      });

      if (hasErrors || error) {
        updateInQueue(trackToRemove, { ...trackToRemove, loading: false, error });
        return;
      }

      if (data) {
        removeFromQueue(input.trackId);
      }
    },
    [removeFromQueue, updateInQueue, enableDisableTrack, dispatch, queue]
  );

  const toggleTrack = useCallback(
    async (input: DiscoveryModeEnableDisableTrackInput) => {
      if (input.enabled) {
        return removeTrack(input);
      } else {
        return addTrack(input);
      }
    },
    [removeTrack, addTrack]
  );

  const count = useMemo(() => queue.filter(t => isTrackLoaded(t)).length, [queue]);

  return { queue, count, toggleTrack };
};

export default useScheduledQueue;
