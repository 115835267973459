/* eslint-disable no-unused-vars,max-len */
import qs from 'query-string';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

// Utilities
import { isMobile } from 'helpers/screen';
import { selectHasPromoInboxAccess } from 'redux/selectors/promo';

// Routes
import * as Async from 'components/App/AsyncRoutes';
import routeMap from 'config/routes';
import { Location } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import PrivacyPolicy from 'routes/PrivacyPolicy';

// Import modals directly. We don't care for them to be asynchonously loaded
// because it adds noticeable lag and occasionally a flashbang while they load,
// depending on internet delay.
import CreateAccountModal from 'routes/CreateAccount';
import { DeleteAccountModal } from 'routes/DeleteAccount';
import DeleteAccountConfirmModal from 'routes/DeleteAccountConfirm';
import { ForgotPasswordModal } from 'routes/ForgotPassword';
import { ForgotUsernameModal } from 'routes/ForgotUsername';
import ShareReleaseLinkModal from 'routes/Release/ShareReleaseLinkModal';
import { ResetPasswordModal } from 'routes/ResetPassword';
import ShareFeedbackModal from 'routes/ShareFeedback';
import SignInModal from 'routes/SignIn';

// Components
import NetworkAuthSuccess from 'components/NetworkAuthSuccess';
import Portal from 'components/Portal';
import RouteLoader from 'components/RouteLoader';
import { UserRoute } from 'components/Router/UserRoute';
import { isProduction } from 'config/constants';
import { RootState } from 'config/store';
import ConfirmAccount from 'routes/ConfirmAccount';
import { default as DiscoveryModeOptInModal } from 'routes/DiscoveryMode/DiscoveryModeModal/OptIn/OptInModal';
import { default as DiscoveryModeOptOutModal } from 'routes/DiscoveryMode/DiscoveryModeModal/OptOut/OptOutModal';

export interface AppSwitchProps extends RouteComponentProps {
  location: Location<{ modal: boolean; notFound: boolean }>;
  // Redux Connect
  hasPromoInboxAccess: boolean;
}

const AppSwitchInner = (props: AppSwitchProps) => {
  const previousLocation = React.useRef(props.location);

  useEffect(() => {
    const { location } = props;
    // set previousLocation if props.location is not modal
    if (props.history.action !== 'POP' && !(location.state && location.state.modal)) {
      previousLocation.current = props.location;
    }
  }, [props.location, props.history.action]);

  const { location, hasPromoInboxAccess, history } = props;

  const isModal = !!(
    !isMobile() &&
    location.state &&
    location.state.modal &&
    previousLocation.current.pathname !== location.pathname
  ); // not initial render

  const fullyEscapeModalFlow = (url?: string) => {
    if (url) {
      history.push(url);
    } else if (
      previousLocation.current &&
      previousLocation.current.pathname !== location.pathname
    ) {
      const { pathname, search, state } = previousLocation.current;
      history.push(pathname + search, state);
    } else {
      history.push('/');
    }
  };

  return (
    <div id="App-content">
      <Suspense fallback={<RouteLoader />}>
        <Switch location={isModal ? previousLocation.current : location}>
          {location.state && location.state.notFound && (
            <Route component={Async.ErrorPage} />
          )}

          <Route
            exact
            path="/r/:uuid"
            component={({ match }: { match: { params: { uuid: string } } }) => {
              const uuid = match.params.uuid;
              window.location.href = `https://go.protonrad.io/${uuid}`;
              return null;
            }}
          />
          <Route exact path="/" component={Async.Radio} />
          <Route exact path={routeMap.confirmation} component={Async.ConfirmationPage} />
          <Route
            exact
            path="/callbacks/:network/success"
            component={NetworkAuthSuccess}
          />
          <Route exact path="/logout" component={Async.SignOut} />
          <Route
            exact
            path={['/account/delete-confirm', '/account/confirm']}
            render={() => <div />}
          />
          <UserRoute exact path="/account" component={Async.Account} />
          <UserRoute
            exact
            path={['/track-stack', '/account/track-stack']}
            isAuthorized={({ user }) => user.roles.artist || user.roles.labelManager}
            component={Async.TrackStack}
          />
          <UserRoute
            path={routeMap.users.root + routeMap.users.verification}
            component={Async.ProUserAccountVerification}
          />
          <UserRoute exact path="/account/:tab" component={Async.Account} />
          <Route exact path="/tracks" component={Async.TracksBrowse} />
          <Route exact path="/mixes" component={Async.MixesBrowse} />
          {/* NOTE: Apache redirects path /browse to /mixes */}
          <Redirect
            exact
            from="/artists/:id/:name/edit"
            to="/artists/:id/:name/settings"
          />
          {/* TODO: Remove once SS links to this page are updated */}
          <Route
            exact
            path="/artists/:artistId/:artistName/verification/:serviceProvider"
            render={({ match, location }) => {
              const { artistId, serviceProvider } = match.params;
              const queryParams = {
                ...qs.parse(location.search),
                artistId
              };

              return (
                <Redirect
                  to={{
                    ...location,
                    search: qs.stringify(queryParams),
                    pathname: `${routeMap.artistProfileWizard.base}/${serviceProvider}-connect`
                  }}
                />
              );
            }}
          />
          <Route path="/artists/:id/:name" component={Async.ArtistBrowse} />
          <Route exact path="/genres/:id/:name" component={Async.GenreBrowse} />
          <Route path="/shows/:id/:name" component={Async.ShowBrowse} />
          <Route
            exact
            path={routeMap.inbox.root + routeMap.inbox.welcome}
            component={Async.InboxWelcome}
          />
          <UserRoute
            path={routeMap.inbox.root}
            component={Async.Inbox}
            isAuthorized={hasPromoInboxAccess}
          />
          <UserRoute
            path={routeMap.admin.youtubeUploader}
            component={Async.YoutubeUploader}
            isAuthorized={({ user }) => {
              return user.roles.admin;
            }}
          />
          {!isProduction && <Route path={routeMap.radio} component={Async.ProtonRadio} />}

          <UserRoute
            path={routeMap.discoveryMode}
            component={Async.DiscoveryModeDashboard}
          />
          {/* NOTE: this has additional promo pool auth defined in the component itself */}
          <UserRoute
            exact
            path={routeMap.labels.root + routeMap.labels.subscribersAdd}
            component={Async.AddSubscriber}
            isAuthorized={({ match, user }) => {
              const labelId = match.params.id!;
              const managesLabel = user.managedLabels.some(label => label.id === labelId);
              if (managesLabel || user.roles.admin) return true;

              return false;
            }}
          />
          <UserRoute
            exact
            path={routeMap.contracts.root + routeMap.contracts.confirmation}
            component={Async.ReleaseContractConfirmation}
          />
          <UserRoute
            path={[
              routeMap.artistProfileWizard.root,
              routeMap.artistProfileWizard.base + '/:context'
            ]}
            component={Async.ArtistProfileWizard}
          />
          <Route path={routeMap.labels.root} component={Async.Label} />
          <Route path={routeMap.releases.root} component={Async.Release} />

          <Route exact path="/explore" component={Async.ExploreMixes} />
          <Route exact path="/top-100/mixes" component={Async.Top100Mixes} />
          <Route exact path="/search" component={Async.SearchResults} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/promo-early-access" component={Async.PromoEarlyAccess} />
          {/**
           * All modal pages which need to be accessible on standalone routes must have their route
           * duplicated into this array. We will be able to simplify this behavior when we upgrade
           * to React Router v6. `path` will not even accept an Array parameter after that upgrade.
           */}
          <Route
            exact
            path={[
              '/sign-in',
              '/create-account',
              '/forgot-username',
              '/forgot-password',
              '/dm-opt-in/*',
              '/dm-opt-out/*'
            ]}
            render={() => <div />}
          />
          <Route exact path={['/reset-password']} render={() => <div />} />
          <Route component={Async.ErrorPage} />
        </Switch>
      </Suspense>

      {/**
       * Modals in the application are always associated with a route. The routes can be static
       * or dynamic, just as with regular application routes.
       */}
      <Portal domNode={document.getElementById('modals-root')}>
        <Switch>
          <Route
            exact
            path="/account/confirm"
            render={() => <ConfirmAccount onClose={fullyEscapeModalFlow} />}
          />
          <Route
            exact
            path="/create-account"
            render={() => <CreateAccountModal onClose={fullyEscapeModalFlow} />}
          />
          <Route
            exact
            path="/sign-in"
            render={() => (
              <SignInModal
                location={previousLocation.current}
                onClose={fullyEscapeModalFlow}
              />
            )}
          />
          <Route exact path="/forgot-username" render={() => <ForgotUsernameModal />} />
          <Route exact path="/forgot-password" render={() => <ForgotPasswordModal />} />
          <Route exact path="/account/delete" render={() => <DeleteAccountModal />} />
          <Route
            exact
            path="/account/delete-confirm"
            render={() => <DeleteAccountConfirmModal onClose={fullyEscapeModalFlow} />}
          />
          <Route
            exact
            path="/reset-password"
            render={() => (
              <ResetPasswordModal onClose={() => fullyEscapeModalFlow('/')} />
            )}
          />
          <Route
            exact
            path="/labels/share-feedback"
            render={() => <ShareFeedbackModal />}
          />
          <Route
            exact
            path="/releases/:id/:name/share"
            render={() => <ShareReleaseLinkModal />}
          />
          <Route path="/dm-opt-in" render={() => <DiscoveryModeOptInModal />} />
          <Route path="/dm-opt-out" render={() => <DiscoveryModeOptOutModal />} />
        </Switch>
      </Portal>
    </div>
  );
};

const AppSwitch = connect((state: RootState) => ({
  hasPromoInboxAccess: selectHasPromoInboxAccess(state)
}))(AppSwitchInner);

export default AppSwitch;
