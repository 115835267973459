import React from 'react';

import { Badge, COLORS } from '@protonradio/proton-ui';
import Avatar from 'components/Avatar';
import Modal from 'components/Modal';
import InviteTrackTile from './InviteTrackTile';
import CopyToClipboardTextInput from 'components/Form/Inputs/CopyToClipboardTextInput';

import useUrlQueryParams from 'hooks/useUrlQueryParams';
import { discoveryModeQueryParams } from 'routes/DiscoveryMode/DiscoveryModeContext';
import styled from 'styled-components';
import { mapTrackEntities } from 'routes/DiscoveryMode/helpers';

import type { EligibleTracksFieldsFragment } from 'gql/graphql';

const ArtistList = styled.div`
  border: 1px solid ${COLORS.GRAYSCALE.BORDER};
  border-radius: 0.4rem;
  padding: 0;
  margin: 2rem auto;
  max-height: 250px;
  overflow-y: auto;
`;

const ArtistRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.8rem;
  border-bottom: 1px solid #eee;
  height: 5.5rem;
  &:last-child {
    border-bottom: none;
  }
`;

const ArtistName = styled.span`
  font-size: 16px;
  margin-left: 1rem;
  color: ${COLORS.GRAYSCALE.GRAY_SUPER_DARK};
`;

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  eligibleTrackData?: EligibleTracksFieldsFragment;
}

const InviteModal = ({ isOpen, onClose, eligibleTrackData }: InviteModalProps) => {
  if (!isOpen || !eligibleTrackData) return null;

  const track = eligibleTrackData.track;
  const enabledEntities = eligibleTrackData.enabledEntities;
  const allEntities = mapTrackEntities(track, enabledEntities);
  const remainingOptIns = allEntities.length - enabledEntities.length;

  const { e: inviter } = useUrlQueryParams(discoveryModeQueryParams);

  return (
    <Modal
      testId="DiscoveryMode-Invite-Modal"
      title={`${remainingOptIns} More ${remainingOptIns === 1 ? 'Opt In' : 'Opt Ins'} Required`}
      onClose={onClose}
    >
      <InviteTrackTile track={track} remainingOptIns={remainingOptIns} />

      <br />

      <p>
        All original, featured and remix artists must be opted in before you can enable
        this track.
      </p>

      <ArtistList>
        {allEntities.map(entity => (
          <ArtistRow key={entity.id}>
            <Avatar
              circle
              imageUrl={entity.imageUrl}
              altTag={entity.name || ''}
              title={entity.name || ''}
            />
            <ArtistName>{entity.name}</ArtistName>

            <div style={{ marginLeft: 'auto' }}>
              {enabledEntities.some(enabled => enabled.name === entity.name) ? (
                <Badge variant="success">Opted In</Badge>
              ) : (
                <Badge variant="warning">Not Opted In</Badge>
              )}
            </div>
          </ArtistRow>
        ))}
      </ArtistList>

      <p>
        Proton is working on a feature to invite artists to Discovery Mode. In the
        meantime, if you're personally in touch with them, try sharing this mobile
        friendly URL:
      </p>

      <CopyToClipboardTextInput
        testId="DiscoveryMode-InviteModal-CopyLink"
        className="mt-3"
        text={`https://protonradio.com/discovery-mode?i=${inviter}`}
      />
    </Modal>
  );
};

export default InviteModal;
