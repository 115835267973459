import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Banner, Button, Badge } from '@protonradio/proton-ui';
import { DiscoveryModeCampaignStateEnum } from 'gql/graphql';

import { showAlert } from 'redux/actions/ui';
import { useAppDispatch } from 'hooks/redux';

import { NavTab, NavTabContainer, NavTabGroup } from 'components/Nav';
import SpotifyLogo from 'components/Icons/SpotifyLogo';
import TabbedHeaderLayout from 'components/TabbedHeaderLayout';

import { discoveryModeQueryParams, useDiscoveryMode } from './DiscoveryModeContext';
import { TableTombstones } from './TableComponents';
import EligibleTracks from './EligibleTracks';
import EnabledTracks from './EnabledTracks';
import DiscoveryModeFilterControls from './DiscoveryModeFilterControls';
import CountdownTimer from './CountdownTimer';
import { BannerWrapper, GradientGreenBackground, LogoTitleWrapper } from './styles';
import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { DiscoveryModeHistoryState } from './DiscoveryModeModal/types';
import useUrlQueryParams from 'hooks/useUrlQueryParams';

const HeaderAction = styled.span`
  font-size: 1.4rem;
  height: 2rem;
  display: flex;
  align-items: center;
`;

const NavTabDisabled = styled.span`
  margin-left: 8px;
  opacity: 0.5;
  pointer-events: none;
`;

export const intercomPaths = {
  placeholder: 'https://www.intercom.com/'
};

export const settingsPaths = {
  notificationSettings: '/account/notifications#discovery-mode',
  optInSettings: '/account/discovery-mode'
};

const DiscoveryMode = () => {
  const [tab, setTab] = useState<'eligible' | 'enabled' | 'insights'>('eligible');
  const {
    loading,
    error,
    eligibleTracklist,
    enabledTracklist,
    discoveryMode,
    selectedEntity,
    hasSeenOnboarding
  } = useDiscoveryMode();
  const { i: inviter, updateParams } = useUrlQueryParams(discoveryModeQueryParams);
  const dispatch = useAppDispatch();
  const history = useHistory<DiscoveryModeHistoryState>();
  const [{ fetching: isOptingIn }, executeMutation] = useMutationWithAlert(
    ToggleDiscoveryModeMutation
  );

  const isOptedIn = selectedEntity?.isOptedIn;
  const historyState = history.location.state;
  const runningCampaignTitle = discoveryMode?.runningCampaign?.title.split(' ')[0];
  const upcomingCampaignTitle = discoveryMode?.upcomingCampaign?.title.split(' ')[0];
  const isBeforeScheduling =
    discoveryMode?.upcomingCampaign?.state ===
    DiscoveryModeCampaignStateEnum.BeforeScheduling;
  const showInviteBanner =
    !isOptedIn && inviter.length > 0 && inviter !== selectedEntity?.name;

  // Handle opt-in success
  useEffect(() => {
    if (historyState?.optInSuccess) {
      dispatch(
        showAlert({
          message:
            "You're opted in to Discovery Mode! You can always click on the chat bubble to let us know if you have any questions.",
          timeout: 10000
        })
      );
      updateParams({ i: undefined }, 'replaceIn');
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
        state: { ...historyState, optInSuccess: false }
      });
    }
  }, [historyState?.optInSuccess]);

  const handleOptIn = async () => {
    const isEntityArtist = selectedEntity?.type === 'artist';
    if (!hasSeenOnboarding) {
      history.replace({
        pathname: '/dm-opt-in',
        state: {
          modal: true,
          modalLocation: '/discovery-mode'
        }
      });
      return null;
    }
    const result = await executeMutation({
      ...(isEntityArtist
        ? { artistId: selectedEntity?.id }
        : { labelId: selectedEntity?.id }),
      enabled: true
    });

    if (result.hasErrors) return;

    history.replace({
      pathname: history.location.pathname,
      state: { ...historyState, optInSuccess: true }
    });
  };

  const OptInBanner = () => {
    return (
      <BannerWrapper>
        <Banner variant="warning" icon data-testid="DiscoveryMode-Banner-OptIn">
          <Banner.Title>
            {selectedEntity?.name} is not opted in to Discovery Mode
          </Banner.Title>
          <Banner.Content>You must opt in to Discovery Mode to use it.</Banner.Content>
          <Banner.Actions>
            <Banner.Action onPress={handleOptIn}>
              {isOptingIn ? 'Opting In...' : 'Opt In'}
            </Banner.Action>
          </Banner.Actions>
        </Banner>
      </BannerWrapper>
    );
  };

  const InvitedByBanner = () => {
    return (
      <BannerWrapper>
        <Banner variant="success" icon data-testid="DiscoveryMode-Banner-Invited">
          <Banner.Title>
            You've been invited to try Discovery Mode by {decodeURIComponent(inviter)}
          </Banner.Title>
          <Banner.Actions>
            <Banner.Action
              onPress={() => {
                history.replace({
                  pathname: '/dm-opt-in',
                  search: history.location.search,
                  state: {
                    modal: true,
                    modalLocation: '/discovery-mode'
                  }
                });
              }}
              variant="secondary"
            >
              Get Started!
            </Banner.Action>
          </Banner.Actions>
        </Banner>
      </BannerWrapper>
    );
  };

  const BeforeSchedulingBanner = () => {
    return (
      <BannerWrapper>
        <Banner variant="warning" icon data-testid="DiscoveryMode-Banner-Warning">
          <Banner.Title>
            Submission deadline for {runningCampaignTitle} has passed!
          </Banner.Title>
        </Banner>
      </BannerWrapper>
    );
  };

  return (
    <TabbedHeaderLayout
      columns={{ default: '100%' }}
      header={{
        title: (
          <LogoTitleWrapper>
            <SpotifyLogo />
            <span>Discovery Mode</span>
          </LogoTitleWrapper>
        ),
        customBackground: <GradientGreenBackground />,
        seo: {
          title: 'Discovery Mode',
          description: 'A dashboard to manage your Spotify Discovery Mode tracks',
          section: 'Discovery Mode'
        },
        renderTabs: () => (
          <NavTabContainer>
            <NavTabGroup>
              <NavTab
                title="Eligible Tracks"
                aside={<Badge variant="secondary">{eligibleTracklist.length}</Badge>}
                onClick={() => setTab('eligible')}
              />
              <NavTab
                title="Enabled Tracks"
                aside={
                  <Badge variant="secondary">
                    {enabledTracklist.filter(track => track.enabled).length}
                  </Badge>
                }
                onClick={() => setTab('enabled')}
              />
              <NavTabDisabled>
                <NavTab
                  title="Insights"
                  aside={<Badge variant="secondary">COMING SOON</Badge>}
                  onClick={() => setTab('insights')}
                />
              </NavTabDisabled>
            </NavTabGroup>
          </NavTabContainer>
        )
        // renderHeaderActions: () => (
        //   <Button variant="translucent" href={settingsPaths.notificationSettings}>
        //     <HeaderAction>Learn More?</HeaderAction>
        //   </Button>
        // )
      }}
      renderMain={() => {
        if (error) {
          return (
            <div className="mt-2 mb-2">
              <Banner
                icon
                variant="danger"
                compact={false}
                data-testid="DiscoveryMode-Banner-Error"
              >
                <Banner.Title>Oops!</Banner.Title>
                <Banner.Content>
                  There was an error loading your Discovery Mode data. Please try again
                  later.
                </Banner.Content>
              </Banner>
            </div>
          );
        }

        if (loading || isOptingIn) {
          return (
            <>
              <DiscoveryModeFilterControls />
              <TableTombstones />
            </>
          );
        }

        return (
          <>
            <DiscoveryModeFilterControls />
            {isOptedIn === false && !showInviteBanner && !isOptingIn && <OptInBanner />}
            {isBeforeScheduling && isOptedIn && <BeforeSchedulingBanner />}
            {showInviteBanner && <InvitedByBanner />}

            <div>
              {tab === 'eligible' && (
                <EligibleTracks
                  isDisabled={isOptedIn === false}
                  countdownTimer={
                    isBeforeScheduling && (
                      <CountdownTimer
                        isOptedIn={isOptedIn}
                        title={upcomingCampaignTitle}
                        schedulingStartsAt={
                          new Date(discoveryMode?.nextSchedulingStartsAt)
                        }
                      />
                    )
                  }
                />
              )}

              {tab === 'enabled' && <EnabledTracks />}
            </div>
          </>
        );
      }}
    />
  );
};

export default DiscoveryMode;
