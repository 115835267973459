import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, TextEmphasis } from '@protonradio/proton-ui';
import CheckmarkCircle from 'components/Icons/CheckmarkCircle';
import Modal from 'components/Modal';
import TextHeader from 'components/TextHeader';

import { COLORS } from '@protonradio/proton-ui/constants';
import { OptInProps } from './OptInModal';
import {
  StyledModal,
  StyledTooltipContent,
  StyledTextHeader,
  StyledLink
} from '../styles';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { SetOnboardingSeenMutation } from 'graphql/mutations/discoveryMode';
import { DiscoveryModeHistoryState } from '../types';

export const OptInSuccess = ({ modalLocation }: OptInProps) => {
  const history = useHistory<DiscoveryModeHistoryState>();
  const [, setOnboardingSeen] = useMutationWithAlert(SetOnboardingSeenMutation);

  useEffect(() => {
    const runMutation = async () => await setOnboardingSeen({});
    void runMutation();
  }, []);

  return (
    <Modal
      testId="DiscoveryMode-OptIn-Success-Modal"
      onClose={() =>
        history.replace({
          pathname: modalLocation,
          search: history.location.search,
          state: {
            optInSuccess: true
          }
        })
      }
    >
      <StyledModal>
        <CheckmarkCircle color={COLORS.SUCCESS.MEDIUM} size={110} />
        <TextHeader type={TextHeader.TYPES.XLARGE}>Congrats! You're Opted In!</TextHeader>
        <div>
          <StyledTextHeader type={TextHeader.TYPES.MEDIUM}>
            Monthly Campaign Opt In
          </StyledTextHeader>
          <p>
            Enable up to 3 tracks for each campaign. Tracks enabled by collaborators don't
            count toward your monthly limit.
          </p>

          <div className="mt-1" />

          <StyledTextHeader type={TextHeader.TYPES.MEDIUM}>
            Automatic Renewal
          </StyledTextHeader>
          <p>
            Each month, tracks with an{' '}
            <TextEmphasis
              tooltipProps={{
                arrow: true,
                content: (
                  <StyledTooltipContent>
                    The percentage of listeners who save or add your track to a playlist
                  </StyledTooltipContent>
                )
              }}
            >
              Intent Rate
            </TextEmphasis>{' '}
            &gt; 1 auto renew to the next campaign. Auto-renewed tracks do NOT count
            toward your monthly limit! Tracks with an Intent Rate &lt; 1 will be
            automatically de-activated from the next campaign.
          </p>
        </div>
      </StyledModal>
      <Modal.ButtonGroup justify="center">
        <Button variant="success">
          <StyledLink
            to={{
              pathname: '/discovery-mode',
              search: history.location.search,
              state: {
                optInSuccess: true
              }
            }}
            data-testid="DM-Go-Button"
          >
            Go!
          </StyledLink>
        </Button>
      </Modal.ButtonGroup>
    </Modal>
  );
};
