import React, { ReactNode, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, TextEmphasis } from '@protonradio/proton-ui';

import CommaList from 'components/CommaList';
import EllipsisLoader from 'components/Loader/EllipsisLoader';
import Modal from 'components/Modal';

import { getFragmentData } from 'gql/fragment-masking';
import {
  DiscoveryModeStateQuery,
  EligibleTracksFragment
} from 'graphql/queries/discoveryMode';
import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import { useQueryWithAlert } from 'hooks/graphql/useQueryWithAlert';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import { StyledLink, StyledTooltipContent } from '../styles';
import { DiscoveryModeTrack } from 'gql/graphql';
import { showAlert } from 'redux/actions/ui';
import { useAppDispatch } from 'hooks/redux';
import { DiscoveryModeHistoryState } from '../types';

const getTrackListText = (
  tracks: DiscoveryModeTrack[],
  type: 'enabled' | 'auto-renewing'
) => {
  if (tracks.length === 0) {
    return `You currently don't have any tracks ${type === 'enabled' ? 'enabled' : 'auto-renewing'} in Discovery Mode.`;
  }

  const baseText = `You currently have ${tracks.length} ${tracks.length === 1 ? 'track' : 'tracks'} ${
    type === 'enabled' ? 'enabled' : 'auto-renewing'
  } in Discovery Mode`;

  if (tracks.length <= 3) {
    return (
      <>
        {baseText}:{' '}
        <strong>
          <CommaList data={tracks.map(t => t.track.title)} ampersand />
        </strong>
        . <Link to="discovery-mode/enabled">Go to your dashboard.</Link>
      </>
    );
  }

  return (
    <>
      {baseText}, including:{' '}
      <strong>
        <CommaList data={tracks.slice(0, 3).map(t => t.track.title)} ampersand />
      </strong>
      .{' '}
      <Link to="/discovery-mode">
        See all {type === 'auto-renewing' ? 'auto-renewing' : 'enabled'} tracks.
      </Link>
    </>
  );
};

const LoadingTooltipContent = ({
  loading,
  children
}: {
  loading: boolean;
  children: ReactNode;
}) => (
  <StyledTooltipContent>{loading ? <EllipsisLoader /> : children}</StyledTooltipContent>
);

const OptOutModal = () => {
  const [{ data, fetching: dataFetching, error: dataError }] = useQueryWithAlert({
    query: DiscoveryModeStateQuery
  });
  const [{ fetching: mutationFetching }, executeMutation] = useMutationWithAlert(
    ToggleDiscoveryModeMutation
  );
  const history = useHistory<DiscoveryModeHistoryState>();
  const location = useLocation<DiscoveryModeHistoryState>();
  const dispatch = useAppDispatch();
  const modalLocation = location.state.modalLocation;
  const entityId = location.state.entityId;

  const tracks = data?.discoveryMode?.runningCampaign?.eligibleTracks;
  const enabledTracklist = getFragmentData(EligibleTracksFragment, tracks) || [];
  const enabledTracks = enabledTracklist.filter(t => t.enabled);
  const autoRenewingTracks = enabledTracklist.filter(
    t => t.intentRate && t.intentRate > 1
  );

  const goBack = () =>
    history.replace({
      pathname: modalLocation ?? '/account/discovery-mode',
      state: {
        optOutSuccess: true
      }
    });

  const handleOptOut = useCallback(async () => {
    try {
      const result = await executeMutation({ artistId: entityId, enabled: false });
      if (result.hasErrors) {
        dispatch(
          showAlert({
            message: 'Unable to opt out. Please try again later',
            timeout: 5000
          })
        );
      }
    } finally {
      goBack();
    }
  }, [executeMutation, entityId, modalLocation]);

  if (dataError || !modalLocation) {
    goBack();
    return null;
  }

  return (
    <Modal onClose={goBack} title="Opt Out of Discovery Mode?">
      <p>
        All tracks{' '}
        <TextEmphasis
          tooltipProps={{
            content: (
              <LoadingTooltipContent loading={dataFetching}>
                {getTrackListText(enabledTracks as DiscoveryModeTrack[], 'enabled')}
              </LoadingTooltipContent>
            )
          }}
        >
          currently enabled
        </TextEmphasis>{' '}
        in Discovery Mode will stay until the end of the month.
      </p>

      <br />

      <p>
        Next month all{' '}
        <TextEmphasis
          tooltipProps={{
            content: (
              <LoadingTooltipContent loading={dataFetching}>
                <p>Tracks with an Intent Rate &gt; 1 auto-renew each month.</p>
                {getTrackListText(
                  autoRenewingTracks as DiscoveryModeTrack[],
                  'auto-renewing'
                )}
              </LoadingTooltipContent>
            )
          }}
        >
          auto-renewing
        </TextEmphasis>{' '}
        tracks will stop.
      </p>

      <Modal.ButtonGroup justify="center">
        <Button variant="secondary" isDisabled={mutationFetching}>
          <StyledLink to={modalLocation ?? '/account/discovery-mode'}>Back</StyledLink>
        </Button>

        <Button
          data-testid="OptOut-Button"
          variant="danger"
          onPress={() => void handleOptOut()}
          isDisabled={mutationFetching}
        >
          {mutationFetching ? 'Opting out...' : 'Opt out'}
        </Button>
      </Modal.ButtonGroup>
    </Modal>
  );
};

export default OptOutModal;
