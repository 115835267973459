/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
};

/** Autogenerated input type of AhoyEventsSeed */
export type AhoyEventsSeedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  releaseId: Scalars['ID']['input'];
};

/** Autogenerated return type of AhoyEventsSeed. */
export type AhoyEventsSeedPayload = {
  __typename?: 'AhoyEventsSeedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  count: Scalars['String']['output'];
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['ID']['output'];
  token: Scalars['ID']['output'];
};

/** Autogenerated input type of ApiKeyGenerate */
export type ApiKeyGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ApiKeyGenerate. */
export type ApiKeyGeneratePayload = {
  __typename?: 'ApiKeyGeneratePayload';
  apiKey: ApiKey;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of ArchiveAccessGrant */
export type ArchiveAccessGrantInput = {
  artistId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveAccessGrant. */
export type ArchiveAccessGrantPayload = {
  __typename?: 'ArchiveAccessGrantPayload';
  artistLabelSubscription?: Maybe<ArtistLabelSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of ArchiveAccessRenew */
export type ArchiveAccessRenewInput = {
  artistId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveAccessRenew. */
export type ArchiveAccessRenewPayload = {
  __typename?: 'ArchiveAccessRenewPayload';
  artistLabelSubscription?: Maybe<ArtistLabelSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of ArchiveAccessRevoke */
export type ArchiveAccessRevokeInput = {
  artistId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveAccessRevoke. */
export type ArchiveAccessRevokePayload = {
  __typename?: 'ArchiveAccessRevokePayload';
  artistLabelSubscription?: Maybe<ArtistLabelSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type Artist = {
  __typename?: 'Artist';
  biography?: Maybe<Scalars['String']['output']>;
  configs?: Maybe<ArtistConfig>;
  dspConnections: Array<ArtistDspConnection>;
  genres: Array<Genre>;
  id: Scalars['ID']['output'];
  image?: Maybe<ArtistImage>;
  isClaimed?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use `is_claimed` instead. */
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  labels: Array<Label>;
  members?: Maybe<Array<ProUser>>;
  name?: Maybe<Scalars['String']['output']>;
  permissions: ArtistPolicy;
  releases: ReleaseConnection;
  slug?: Maybe<Scalars['String']['output']>;
  spotifyPopularity: Scalars['Int']['output'];
  tracks: Array<Track>;
  viewer?: Maybe<ArtistViewer>;
};

export type ArtistReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ArtistConfig = {
  __typename?: 'ArtistConfig';
  hasDiscoveryModeEnabled: Scalars['Boolean']['output'];
  isCurator: Scalars['Boolean']['output'];
  skipSoundcloudVerification: Scalars['Boolean']['output'];
};

/** The connection type for Artist. */
export type ArtistConnection = {
  __typename?: 'ArtistConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ArtistEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Artist>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ArtistDspConnection = {
  __typename?: 'ArtistDspConnection';
  artistId: Scalars['ID']['output'];
  artistName: Scalars['String']['output'];
  avatar?: Maybe<ImageAsset>;
  dsp: Dsp;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isVerifiable: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

/** An edge in a connection. */
export type ArtistEdge = {
  __typename?: 'ArtistEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Artist>;
};

export type ArtistImage = {
  __typename?: 'ArtistImage';
  type: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ArtistLabelSubscription = {
  __typename?: 'ArtistLabelSubscription';
  archiveAccessExpiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  archiveAccessGrantedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  artist: Artist;
  hasArchiveAccess: Scalars['Boolean']['output'];
  hasPromoAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label: Label;
  lastReactedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type ArtistPolicy = {
  __typename?: 'ArtistPolicy';
  reactToPromos: Scalars['Boolean']['output'];
};

export type ArtistSeed = {
  name?: InputMaybe<Scalars['String']['input']>;
  ref: Scalars['ID']['input'];
  userRefs: Array<Scalars['ID']['input']>;
};

export type ArtistViewer = {
  __typename?: 'ArtistViewer';
  id: Scalars['ID']['output'];
  isArtistMember: Scalars['Boolean']['output'];
  isLabelManager: Scalars['Boolean']['output'];
};

export type AudioAsset = {
  __typename?: 'AudioAsset';
  filename: Scalars['String']['output'];
  filesize: Scalars['Int']['output'];
  md5?: Maybe<Scalars['String']['output']>;
};

export enum AudioQualityEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Original = 'ORIGINAL'
}

/** Autogenerated input type of Authenticate */
export type AuthenticateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Autogenerated return type of Authenticate. */
export type AuthenticatePayload = {
  __typename?: 'AuthenticatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  loggedIn: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of AuthenticateWithMagicLink */
export type AuthenticateWithMagicLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
};

/** Autogenerated return type of AuthenticateWithMagicLink. */
export type AuthenticateWithMagicLinkPayload = {
  __typename?: 'AuthenticateWithMagicLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  loggedIn: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<User>;
};

export type BooleanFilterInput = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommunicationChannel = {
  __typename?: 'CommunicationChannel';
  channel: CommunicationChannelEnum;
  emailField?: Maybe<EmailFieldEnum>;
  id: Scalars['ID']['output'];
  isVerified: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CommunicationChannelAdd */
export type CommunicationChannelAddInput = {
  channel: CommunicationChannelEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

/** Autogenerated return type of CommunicationChannelAdd. */
export type CommunicationChannelAddPayload = {
  __typename?: 'CommunicationChannelAddPayload';
  channel?: Maybe<CommunicationChannel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of CommunicationChannelDeliverOtp */
export type CommunicationChannelDeliverOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communicationChannelId: Scalars['ID']['input'];
  grecaptchaToken?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CommunicationChannelDeliverOtp. */
export type CommunicationChannelDeliverOtpPayload = {
  __typename?: 'CommunicationChannelDeliverOtpPayload';
  channel?: Maybe<CommunicationChannel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export enum CommunicationChannelEnum {
  Email = 'EMAIL',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP'
}

/** Autogenerated input type of CommunicationChannelVerifyOtp */
export type CommunicationChannelVerifyOtpInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  communicationChannelId: Scalars['ID']['input'];
};

/** Autogenerated return type of CommunicationChannelVerifyOtp. */
export type CommunicationChannelVerifyOtpPayload = {
  __typename?: 'CommunicationChannelVerifyOtpPayload';
  channel?: Maybe<CommunicationChannel>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** The connection type for Artist. */
export type Connection = {
  __typename?: 'Connection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ConnectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Artist>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ConnectionEdge = {
  __typename?: 'ConnectionEdge';
  addedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  archiveAccessExpiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  archiveAccessGrantedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  averageRating: Scalars['Float']['output'];
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  hasArchiveAccess: Scalars['Boolean']['output'];
  hasPromoAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFollowing: Scalars['Boolean']['output'];
  lastReactedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The item at the end of the edge. */
  node?: Maybe<Artist>;
};

export enum ConnectionTypeEnum {
  Dropbox = 'DROPBOX',
  Google = 'GOOGLE',
  Paypal = 'PAYPAL',
  Soundcloud = 'SOUNDCLOUD',
  Spotify = 'SPOTIFY'
}

export enum CoverArtSizeEnum {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Original = 'ORIGINAL',
  Small = 'SMALL',
  Thumb = 'THUMB'
}

export enum CryptoNetworkEnum {
  Arbitrum = 'ARBITRUM',
  Avalanche = 'AVALANCHE',
  Base = 'BASE',
  Ethereum = 'ETHEREUM',
  Optimism = 'OPTIMISM',
  Polygon = 'POLYGON'
}

export type CustomLink = {
  __typename?: 'CustomLink';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DateFilterInput = {
  eq?: InputMaybe<Scalars['ISO8601Date']['input']>;
  gt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  gteq?: InputMaybe<Scalars['ISO8601Date']['input']>;
  in?: InputMaybe<Array<Scalars['ISO8601Date']['input']>>;
  lt?: InputMaybe<Scalars['ISO8601Date']['input']>;
  lteq?: InputMaybe<Scalars['ISO8601Date']['input']>;
  notEq?: InputMaybe<Scalars['ISO8601Date']['input']>;
  notIn?: InputMaybe<Array<Scalars['ISO8601Date']['input']>>;
};

export type DateTimeFilterInput = {
  eq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  gt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  gteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
  lt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  lteq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  notEq?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  notIn?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>;
};

export type DiscoveryMode = {
  __typename?: 'DiscoveryMode';
  campaign: DiscoveryModeCampaign;
  nextSchedulingStartsAt: Scalars['ISO8601DateTime']['output'];
  runningCampaign: DiscoveryModeCampaign;
  upcomingCampaign: DiscoveryModeCampaign;
};

export type DiscoveryModeCampaignArgs = {
  id: Scalars['ID']['input'];
};

export type DiscoveryModeCampaign = {
  __typename?: 'DiscoveryModeCampaign';
  eligibleTrack?: Maybe<DiscoveryModeTrack>;
  eligibleTracks: Array<DiscoveryModeTrack>;
  endDate: Scalars['ISO8601Date']['output'];
  id: Scalars['ID']['output'];
  schedulingEndsAt: Scalars['ISO8601DateTime']['output'];
  schedulingStartsAt: Scalars['ISO8601DateTime']['output'];
  startDate: Scalars['ISO8601Date']['output'];
  state: DiscoveryModeCampaignStateEnum;
  title: Scalars['String']['output'];
};

export type DiscoveryModeCampaignEligibleTrackArgs = {
  id: Scalars['ID']['input'];
};

export type DiscoveryModeCampaignEligibleTracksArgs = {
  filterBy?: InputMaybe<DiscoveryModeTrackFilterInput>;
  orderBy?: InputMaybe<DiscoveryModeTrackOrderInput>;
};

export enum DiscoveryModeCampaignStateEnum {
  AfterScheduling = 'AFTER_SCHEDULING',
  BeforeScheduling = 'BEFORE_SCHEDULING',
  Future = 'FUTURE',
  Past = 'PAST',
  Running = 'RUNNING',
  Scheduling = 'SCHEDULING'
}

/** Autogenerated input type of DiscoveryModeEligibleTracksIngest */
export type DiscoveryModeEligibleTracksIngestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  filename: Scalars['String']['input'];
};

/** Autogenerated return type of DiscoveryModeEligibleTracksIngest. */
export type DiscoveryModeEligibleTracksIngestPayload = {
  __typename?: 'DiscoveryModeEligibleTracksIngestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DiscoveryModeEnableDisableTrack */
export type DiscoveryModeEnableDisableTrackInput = {
  artistId?: InputMaybe<Scalars['ID']['input']>;
  campaignId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  labelId?: InputMaybe<Scalars['ID']['input']>;
  trackId: Scalars['ID']['input'];
};

/** Autogenerated return type of DiscoveryModeEnableDisableTrack. */
export type DiscoveryModeEnableDisableTrackPayload = {
  __typename?: 'DiscoveryModeEnableDisableTrackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  discoveryModeTrack?: Maybe<DiscoveryModeTrack>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of DiscoveryModeOnboardingSeenUpdate */
export type DiscoveryModeOnboardingSeenUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DiscoveryModeOnboardingSeenUpdate. */
export type DiscoveryModeOnboardingSeenUpdatePayload = {
  __typename?: 'DiscoveryModeOnboardingSeenUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  viewer?: Maybe<User>;
};

/** Autogenerated input type of DiscoveryModeOptInOut */
export type DiscoveryModeOptInOutInput = {
  artistId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  labelId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of DiscoveryModeOptInOut. */
export type DiscoveryModeOptInOutPayload = {
  __typename?: 'DiscoveryModeOptInOutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type DiscoveryModeTrack = {
  __typename?: 'DiscoveryModeTrack';
  approved: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  enabledEntities: Array<Entity>;
  id: Scalars['ID']['output'];
  intentRate?: Maybe<Scalars['Float']['output']>;
  listeners?: Maybe<Scalars['Int']['output']>;
  listenersLift?: Maybe<Scalars['Float']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  spotifyRecommend: Scalars['Boolean']['output'];
  streams?: Maybe<Scalars['Int']['output']>;
  streamsLift?: Maybe<Scalars['Float']['output']>;
  track: Track;
};

export type DiscoveryModeTrackFilterInput = {
  approved?: InputMaybe<BooleanFilterInput>;
  artistNames?: InputMaybe<StringFilterInput>;
  labelName?: InputMaybe<StringFilterInput>;
  trackName?: InputMaybe<StringFilterInput>;
};

export type DiscoveryModeTrackOrderInput = {
  labelName?: InputMaybe<OrderDirectionEnum>;
  trackName?: InputMaybe<OrderDirectionEnum>;
};

/** Autogenerated input type of DismissLabelSubscription */
export type DismissLabelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of DismissLabelSubscription. */
export type DismissLabelSubscriptionPayload = {
  __typename?: 'DismissLabelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  label?: Maybe<Label>;
};

export type Dsp = {
  __typename?: 'Dsp';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum EmailFieldEnum {
  PaypalEmail = 'PAYPAL_EMAIL',
  PromoEmail = 'PROMO_EMAIL',
  ProEmail = 'PRO_EMAIL',
  UserEmail = 'USER_EMAIL'
}

export type Entity = {
  __typename?: 'Entity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** A user-readable error */
export type Error = {
  __typename?: 'Error';
  /** A description of the error */
  message: Scalars['String']['output'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']['output']>>;
};

export enum EventDateRangeEnum {
  PreRelease = 'PRE_RELEASE'
}

export type EventFilterInput = {
  date?: InputMaybe<DateFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  time?: InputMaybe<DateTimeFilterInput>;
};

export type EventGroup = {
  __typename?: 'EventGroup';
  city?: Maybe<Array<EventGroupResultType>>;
  country?: Maybe<Array<EventGroupResultType>>;
  date?: Maybe<Array<EventGroupResultType>>;
  eventName?: Maybe<Array<EventGroupResultType>>;
  month?: Maybe<Array<EventGroupResultType>>;
  region?: Maybe<Array<EventGroupResultType>>;
  sharer?: Maybe<Array<EventGroupResultType>>;
  week?: Maybe<Array<EventGroupResultType>>;
  year?: Maybe<Array<EventGroupResultType>>;
};

export type EventGroupEventNameArgs = {
  dateRange?: InputMaybe<EventDateRangeEnum>;
  filterBy?: InputMaybe<EventFilterInput>;
  orderBy?: InputMaybe<EventOrderInput>;
  value?: InputMaybe<EventTypeEnum>;
};

export type EventGroupResultType = {
  __typename?: 'EventGroupResultType';
  count: Scalars['Int']['output'];
  groupBy: EventGroup;
  item?: Maybe<Profile>;
  key?: Maybe<Scalars['String']['output']>;
};

export type EventOrderInput = {
  count?: InputMaybe<OrderDirectionEnum>;
  name?: InputMaybe<OrderDirectionEnum>;
};

export enum EventTypeEnum {
  /** All pre save related events */
  PreSave = 'PRE_SAVE',
  /** All pre save create events */
  PreSaveCreate = 'PRE_SAVE_CREATE',
  /** All pre save delivered events */
  PreSaveDelivered = 'PRE_SAVE_DELIVERED',
  /** All release link related events */
  ReleaseLink = 'RELEASE_LINK',
  /** All release link delivered events */
  ReleaseLinkDelivered = 'RELEASE_LINK_DELIVERED',
  /** All release link forward events */
  ReleaseLinkForward = 'RELEASE_LINK_FORWARD',
  /** All release link view events */
  ReleaseLinkView = 'RELEASE_LINK_VIEW'
}

/** Autogenerated input type of FollowLabelSubscription */
export type FollowLabelSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of FollowLabelSubscription. */
export type FollowLabelSubscriptionPayload = {
  __typename?: 'FollowLabelSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  label?: Maybe<Label>;
};

export type ForwarderInfo = {
  __typename?: 'ForwarderInfo';
  linkText?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  originalUrl?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  status?: Maybe<ReleaseStatusEnum>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Genre = {
  __typename?: 'Genre';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  occurrences: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
};

export type GroupResultType = {
  __typename?: 'GroupResultType';
  count: Scalars['Int']['output'];
  groupBy: SystemEventGroup;
  key?: Maybe<Scalars['String']['output']>;
};

export type IdFilterInput = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  notEq?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ImageAsset = {
  __typename?: 'ImageAsset';
  height: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type Label = {
  __typename?: 'Label';
  activeEndYear?: Maybe<Scalars['Int']['output']>;
  activeStartYear?: Maybe<Scalars['Int']['output']>;
  artists: ArtistConnection;
  beatportFollowerCount?: Maybe<Scalars['Int']['output']>;
  configs?: Maybe<LabelConfig>;
  countries: Array<Scalars['String']['output']>;
  customForwarders?: Maybe<Array<ReleaseLinkCustomForwarder>>;
  customLinks: Array<CustomLink>;
  description?: Maybe<Scalars['String']['output']>;
  genres: Array<Genre>;
  /** Whether the label can schedule a new releases */
  hasCatalogEnabled?: Maybe<Scalars['Boolean']['output']>;
  hasPromoAccess?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Whether the label has been created on Beatport or has fully switched from another distributor */
  isActive?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<ImageAsset>;
  name?: Maybe<Scalars['String']['output']>;
  promoAccessState?: Maybe<PromoAccessStateEnum>;
  recommendedSubscribers?: Maybe<LabelSubscriberPagination>;
  releases: ReleaseConnection;
  slug?: Maybe<Scalars['String']['output']>;
  soundcloudFollowerCount?: Maybe<Scalars['Int']['output']>;
  subscribers?: Maybe<LabelSubscriberPagination>;
  subscribersConnection?: Maybe<Connection>;
  topArtists: Array<Artist>;
  viewer?: Maybe<LabelViewer>;
};

export type LabelArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type LabelLogoArgs = {
  size: CoverArtSizeEnum;
};

export type LabelRecommendedSubscribersArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type LabelReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<ReleaseFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ReleaseOrderInput>;
};

export type LabelSubscribersArgs = {
  filterBy?: InputMaybe<LabelSubscriberFilterInput>;
  orderBy?: InputMaybe<LabelSubscriberOrderInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type LabelSubscribersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterBy?: InputMaybe<LabelSubscriberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LabelSubscriberOrderInput>;
};

export type LabelConfig = {
  __typename?: 'LabelConfig';
  curationEnabled: Scalars['Boolean']['output'];
  hasDiscoveryModeEnabled: Scalars['Boolean']['output'];
  hasSeenReleaseLinkOnboarding: Scalars['Boolean']['output'];
  onboardedTransfer: Scalars['Boolean']['output'];
  promoAccessState?: Maybe<PromoAccessStateEnum>;
  releaseLinkFeatureEnabled: Scalars['Boolean']['output'];
  releaseLinkState?: Maybe<ReleaseLinkStateEnum>;
  releaseLinkStreamingEnabled: Scalars['Boolean']['output'];
  strategicPartner: Scalars['Boolean']['output'];
};

/** Autogenerated input type of LabelConfigRemove */
export type LabelConfigRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configKey: Scalars['String']['input'];
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of LabelConfigRemove. */
export type LabelConfigRemovePayload = {
  __typename?: 'LabelConfigRemovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  labelConfigs?: Maybe<LabelConfig>;
};

/** Autogenerated input type of LabelConfigUpdate */
export type LabelConfigUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configKey: Scalars['String']['input'];
  configValue: Scalars['JSON']['input'];
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of LabelConfigUpdate. */
export type LabelConfigUpdatePayload = {
  __typename?: 'LabelConfigUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  labelConfigs?: Maybe<LabelConfig>;
};

/** The connection type for Label. */
export type LabelConnection = {
  __typename?: 'LabelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LabelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Label>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LabelEdge = {
  __typename?: 'LabelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Label>;
};

export type LabelSeed = {
  managerArtistRef: Scalars['ID']['input'];
  ref: Scalars['ID']['input'];
  traits?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** An edge in a connection. */
export type LabelSubscriberEdge = {
  __typename?: 'LabelSubscriberEdge';
  addedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  archiveAccessExpiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  archiveAccessGrantedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  averageRating: Scalars['Float']['output'];
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  hasArchiveAccess: Scalars['Boolean']['output'];
  hasPromoAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFollowing: Scalars['Boolean']['output'];
  lastReactedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The item at the end of the edge. */
  node?: Maybe<Artist>;
};

export type LabelSubscriberFilterInput = {
  archiveAccess?: InputMaybe<BooleanFilterInput>;
  artistName?: InputMaybe<StringFilterInput>;
  isFollowing?: InputMaybe<BooleanFilterInput>;
  isRoster?: InputMaybe<BooleanFilterInput>;
  recentlyReacted?: InputMaybe<BooleanFilterInput>;
};

/** Autogenerated input type of LabelSubscriberInvite */
export type LabelSubscriberInviteInput = {
  artistId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of LabelSubscriberInvite. */
export type LabelSubscriberInvitePayload = {
  __typename?: 'LabelSubscriberInvitePayload';
  artist?: Maybe<Artist>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type LabelSubscriberOrderInput = {
  artistName?: InputMaybe<OrderDirectionEnum>;
  artistPopularity?: InputMaybe<OrderDirectionEnum>;
  averageRating?: InputMaybe<OrderDirectionEnum>;
  dateInvited?: InputMaybe<OrderDirectionEnum>;
  lastReactedAt?: InputMaybe<OrderDirectionEnum>;
};

export type LabelSubscriberPagination = {
  __typename?: 'LabelSubscriberPagination';
  edges: Array<LabelSubscriberEdge>;
  pageInfo: PaginationInfo;
};

/** Autogenerated input type of LabelSubscriberRemove */
export type LabelSubscriberRemoveInput = {
  artistId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of LabelSubscriberRemove. */
export type LabelSubscriberRemovePayload = {
  __typename?: 'LabelSubscriberRemovePayload';
  artist?: Maybe<Artist>;
  artistLabelSubscription?: Maybe<ArtistLabelSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export enum LabelSubscriptionEnum {
  Available = 'AVAILABLE',
  Dismissed = 'DISMISSED',
  Following = 'FOLLOWING',
  Invited = 'INVITED',
  Muted = 'MUTED'
}

export type LabelViewer = {
  __typename?: 'LabelViewer';
  hasReleasesOnLabel: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isLabelManager: Scalars['Boolean']['output'];
  shouldDisplayReleaseLinkOnboarding: Scalars['Boolean']['output'];
};

export type LinkSharer = {
  __typename?: 'LinkSharer';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MixVideoSettings = {
  __typename?: 'MixVideoSettings';
  bucket?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of MixVideoSettingsUpdate */
export type MixVideoSettingsUpdateInput = {
  bucket: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mixId?: InputMaybe<Scalars['ID']['input']>;
  path: Scalars['String']['input'];
  stagedMixId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of MixVideoSettingsUpdate. */
export type MixVideoSettingsUpdatePayload = {
  __typename?: 'MixVideoSettingsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  mix?: Maybe<RadioMix>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ahoyEventsSeed?: Maybe<AhoyEventsSeedPayload>;
  apiKeyGenerate?: Maybe<ApiKeyGeneratePayload>;
  archiveAccessGrant?: Maybe<ArchiveAccessGrantPayload>;
  archiveAccessRenew?: Maybe<ArchiveAccessRenewPayload>;
  archiveAccessRevoke?: Maybe<ArchiveAccessRevokePayload>;
  authenticate?: Maybe<AuthenticatePayload>;
  authenticateWithMagicLink?: Maybe<AuthenticateWithMagicLinkPayload>;
  communicationChannelAdd?: Maybe<CommunicationChannelAddPayload>;
  communicationChannelDeliverOtp?: Maybe<CommunicationChannelDeliverOtpPayload>;
  communicationChannelVerifyOtp?: Maybe<CommunicationChannelVerifyOtpPayload>;
  discoveryModeEligibleTracksIngest?: Maybe<DiscoveryModeEligibleTracksIngestPayload>;
  discoveryModeEnableDisableTrack?: Maybe<DiscoveryModeEnableDisableTrackPayload>;
  discoveryModeOnboardingSeenUpdate?: Maybe<DiscoveryModeOnboardingSeenUpdatePayload>;
  discoveryModeOptInOut?: Maybe<DiscoveryModeOptInOutPayload>;
  dismissLabelSubscription?: Maybe<DismissLabelSubscriptionPayload>;
  followLabelSubscription?: Maybe<FollowLabelSubscriptionPayload>;
  labelConfigRemove?: Maybe<LabelConfigRemovePayload>;
  labelConfigUpdate?: Maybe<LabelConfigUpdatePayload>;
  labelSubscriberInvite?: Maybe<LabelSubscriberInvitePayload>;
  labelSubscriberRemove?: Maybe<LabelSubscriberRemovePayload>;
  mixVideoSettingsUpdate?: Maybe<MixVideoSettingsUpdatePayload>;
  paymentMethodAdd?: Maybe<PaymentMethodAddPayload>;
  paymentMethodConfirm?: Maybe<PaymentMethodConfirmPayload>;
  paymentMethodRemove?: Maybe<PaymentMethodRemovePayload>;
  paymentMethodUpdate?: Maybe<PaymentMethodUpdatePayload>;
  preSaveAdd?: Maybe<PreSaveAddPayload>;
  privacyPolicyConsent?: Maybe<PrivacyPolicyConsentPayload>;
  proUserUpdate?: Maybe<ProUserUpdatePayload>;
  releaseLinkCreate?: Maybe<ReleaseLinkCreatePayload>;
  releaseLinkCustomForwarderCreate?: Maybe<ReleaseLinkCustomForwarderCreatePayload>;
  releaseLinkCustomForwarderDelete?: Maybe<ReleaseLinkCustomForwarderDeletePayload>;
  releaseLinkCustomForwarderReorder?: Maybe<ReleaseLinkCustomForwarderReorderPayload>;
  releaseLinkCustomForwarderUpdate?: Maybe<ReleaseLinkCustomForwarderUpdatePayload>;
  releaseLinkNotificationConfigUpdate?: Maybe<NotificationConfigUpdatePayload>;
  releaseLinkOnboardingSeenUpdate?: Maybe<ReleaseLinkOnboardingSeenUpdatePayload>;
  releaseLinkStateUpdate?: Maybe<ReleaseLinkStateUpdatePayload>;
  requestMagicLink?: Maybe<RequestMagicLinkPayload>;
  spotifyPlaylistAdd?: Maybe<SpotifyPlaylistAddPayload>;
  spotifyPlaylistDelete?: Maybe<SpotifyPlaylistDeletePayload>;
  testDatabaseReleaseLinkSeed?: Maybe<TestDatabaseReleaseLinkSeedPayload>;
  userSettingUpdate?: Maybe<UserSettingUpdatePayload>;
};

export type MutationAhoyEventsSeedArgs = {
  input: AhoyEventsSeedInput;
};

export type MutationApiKeyGenerateArgs = {
  input: ApiKeyGenerateInput;
};

export type MutationArchiveAccessGrantArgs = {
  input: ArchiveAccessGrantInput;
};

export type MutationArchiveAccessRenewArgs = {
  input: ArchiveAccessRenewInput;
};

export type MutationArchiveAccessRevokeArgs = {
  input: ArchiveAccessRevokeInput;
};

export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};

export type MutationAuthenticateWithMagicLinkArgs = {
  input: AuthenticateWithMagicLinkInput;
};

export type MutationCommunicationChannelAddArgs = {
  input: CommunicationChannelAddInput;
};

export type MutationCommunicationChannelDeliverOtpArgs = {
  input: CommunicationChannelDeliverOtpInput;
};

export type MutationCommunicationChannelVerifyOtpArgs = {
  input: CommunicationChannelVerifyOtpInput;
};

export type MutationDiscoveryModeEligibleTracksIngestArgs = {
  input: DiscoveryModeEligibleTracksIngestInput;
};

export type MutationDiscoveryModeEnableDisableTrackArgs = {
  input: DiscoveryModeEnableDisableTrackInput;
};

export type MutationDiscoveryModeOnboardingSeenUpdateArgs = {
  input: DiscoveryModeOnboardingSeenUpdateInput;
};

export type MutationDiscoveryModeOptInOutArgs = {
  input: DiscoveryModeOptInOutInput;
};

export type MutationDismissLabelSubscriptionArgs = {
  input: DismissLabelSubscriptionInput;
};

export type MutationFollowLabelSubscriptionArgs = {
  input: FollowLabelSubscriptionInput;
};

export type MutationLabelConfigRemoveArgs = {
  input: LabelConfigRemoveInput;
};

export type MutationLabelConfigUpdateArgs = {
  input: LabelConfigUpdateInput;
};

export type MutationLabelSubscriberInviteArgs = {
  input: LabelSubscriberInviteInput;
};

export type MutationLabelSubscriberRemoveArgs = {
  input: LabelSubscriberRemoveInput;
};

export type MutationMixVideoSettingsUpdateArgs = {
  input: MixVideoSettingsUpdateInput;
};

export type MutationPaymentMethodAddArgs = {
  input: PaymentMethodAddInput;
};

export type MutationPaymentMethodConfirmArgs = {
  input: PaymentMethodConfirmInput;
};

export type MutationPaymentMethodRemoveArgs = {
  input: PaymentMethodRemoveInput;
};

export type MutationPaymentMethodUpdateArgs = {
  input: PaymentMethodUpdateInput;
};

export type MutationPreSaveAddArgs = {
  input: PreSaveAddInput;
};

export type MutationPrivacyPolicyConsentArgs = {
  input: PrivacyPolicyConsentInput;
};

export type MutationProUserUpdateArgs = {
  input: ProUserUpdateInput;
};

export type MutationReleaseLinkCreateArgs = {
  input: ReleaseLinkCreateInput;
};

export type MutationReleaseLinkCustomForwarderCreateArgs = {
  input: ReleaseLinkCustomForwarderCreateInput;
};

export type MutationReleaseLinkCustomForwarderDeleteArgs = {
  input: ReleaseLinkCustomForwarderDeleteInput;
};

export type MutationReleaseLinkCustomForwarderReorderArgs = {
  input: ReleaseLinkCustomForwarderReorderInput;
};

export type MutationReleaseLinkCustomForwarderUpdateArgs = {
  input: ReleaseLinkCustomForwarderUpdateInput;
};

export type MutationReleaseLinkNotificationConfigUpdateArgs = {
  input: NotificationConfigUpdateInput;
};

export type MutationReleaseLinkOnboardingSeenUpdateArgs = {
  input: ReleaseLinkOnboardingSeenUpdateInput;
};

export type MutationReleaseLinkStateUpdateArgs = {
  input: ReleaseLinkStateUpdateInput;
};

export type MutationRequestMagicLinkArgs = {
  input: RequestMagicLinkInput;
};

export type MutationSpotifyPlaylistAddArgs = {
  input: SpotifyPlaylistAddInput;
};

export type MutationSpotifyPlaylistDeleteArgs = {
  input: SpotifyPlaylistDeleteInput;
};

export type MutationTestDatabaseReleaseLinkSeedArgs = {
  input: TestDatabaseReleaseLinkSeedInput;
};

export type MutationUserSettingUpdateArgs = {
  input: UserSettingUpdateInput;
};

export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  artist?: Maybe<Artist>;
  communicationChannel?: Maybe<CommunicationChannel>;
  enabled: Scalars['Boolean']['output'];
  feature: NotificationConfigFeatureEnum;
  id: Scalars['ID']['output'];
  label?: Maybe<Label>;
};

export enum NotificationConfigFeatureEnum {
  ReleaseLink = 'RELEASE_LINK'
}

/** Autogenerated input type of NotificationConfigUpdate */
export type NotificationConfigUpdateInput = {
  artistId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communicationChannelId?: InputMaybe<Scalars['ID']['input']>;
  enabled: Scalars['Boolean']['input'];
  labelId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of NotificationConfigUpdate. */
export type NotificationConfigUpdatePayload = {
  __typename?: 'NotificationConfigUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  config?: Maybe<NotificationConfig>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export enum OrderDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  nodeCount: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  allowOverride: Scalars['Boolean']['output'];
  automatic: Scalars['Boolean']['output'];
  defaultPaymentProvider: PaymentProvider;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needsConfirmation?: Maybe<Scalars['Boolean']['output']>;
  paymentProviders: Array<PaymentProvider>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of PaymentMethodAdd */
export type PaymentMethodAddInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<CryptoNetworkEnum>;
  overrideRestriction?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodId: Scalars['ID']['input'];
  preferredPaymentProviderId?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PaymentMethodAdd. */
export type PaymentMethodAddPayload = {
  __typename?: 'PaymentMethodAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  paymentMethodConfig?: Maybe<PaymentMethodConfig>;
};

export type PaymentMethodConfig = {
  __typename?: 'PaymentMethodConfig';
  address?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
  needsConfirmation: Scalars['Boolean']['output'];
  network?: Maybe<CryptoNetworkEnum>;
  overrideRestriction: Scalars['Boolean']['output'];
  paymentMethod: PaymentMethod;
  paymentPending: Scalars['Boolean']['output'];
  preferredPaymentProvider?: Maybe<PaymentProvider>;
  status: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PaymentMethodConfirm */
export type PaymentMethodConfirmInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PaymentMethodConfirm. */
export type PaymentMethodConfirmPayload = {
  __typename?: 'PaymentMethodConfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  paymentMethodConfig?: Maybe<PaymentMethodConfig>;
};

/** An edge in a connection. */
export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  /** The item at the end of the edge. */
  node?: Maybe<PaymentMethod>;
};

export type PaymentMethodPagination = {
  __typename?: 'PaymentMethodPagination';
  edges: Array<PaymentMethodEdge>;
  pageInfo: PaginationInfo;
};

/** Autogenerated input type of PaymentMethodRemove */
export type PaymentMethodRemoveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PaymentMethodRemove. */
export type PaymentMethodRemovePayload = {
  __typename?: 'PaymentMethodRemovePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  paymentMethodConfig?: Maybe<PaymentMethodConfig>;
};

/** Autogenerated input type of PaymentMethodUpdate */
export type PaymentMethodUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  network?: InputMaybe<CryptoNetworkEnum>;
  overrideRestriction?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethodId: Scalars['ID']['input'];
  preferredPaymentProviderId?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of PaymentMethodUpdate. */
export type PaymentMethodUpdatePayload = {
  __typename?: 'PaymentMethodUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  paymentMethodConfig?: Maybe<PaymentMethodConfig>;
};

export type PaymentProvider = {
  __typename?: 'PaymentProvider';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  paymentMethod?: Maybe<PaymentMethod>;
  status: Scalars['String']['output'];
};

export enum PreSavableStoreEnum {
  AmazonMusic = 'AMAZON_MUSIC',
  Anghami = 'ANGHAMI',
  AppleMusic = 'APPLE_MUSIC',
  Bandcamp = 'BANDCAMP',
  Deezer = 'DEEZER',
  Netease = 'NETEASE',
  Pandora = 'PANDORA',
  Soundcloud = 'SOUNDCLOUD',
  Spotify = 'SPOTIFY',
  Tidal = 'TIDAL',
  TiktokMusic = 'TIKTOK_MUSIC',
  Youtube = 'YOUTUBE'
}

export type PreSave = {
  __typename?: 'PreSave';
  communicationChannel: CommunicationChannel;
  id: Scalars['ID']['output'];
  release: Release;
  store?: Maybe<PreSavableStoreEnum>;
};

/** Autogenerated input type of PreSaveAdd */
export type PreSaveAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  communicationChannelId: Scalars['ID']['input'];
  releaseLinkId: Scalars['ID']['input'];
  store: PreSavableStoreEnum;
};

/** Autogenerated return type of PreSaveAdd. */
export type PreSaveAddPayload = {
  __typename?: 'PreSaveAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  preSave?: Maybe<PreSave>;
};

export type PrivacyPolicyConsent = {
  __typename?: 'PrivacyPolicyConsent';
  consentedAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  timestamp: Scalars['Int']['output'];
  version: Scalars['ID']['output'];
};

/** Autogenerated input type of PrivacyPolicyConsent */
export type PrivacyPolicyConsentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['ID']['input'];
};

/** Autogenerated return type of PrivacyPolicyConsent. */
export type PrivacyPolicyConsentPayload = {
  __typename?: 'PrivacyPolicyConsentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  privacyPolicyConsents?: Maybe<Array<PrivacyPolicyConsent>>;
};

export type ProUser = {
  __typename?: 'ProUser';
  address?: Maybe<Scalars['String']['output']>;
  artists: Array<Artist>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasConfirmedDetails?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isActivated: Scalars['Boolean']['output'];
  isClaimed: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  paypalEmail?: Maybe<Scalars['String']['output']>;
  permissions: ProUserPolicy;
  proEmail: Scalars['String']['output'];
  promoEmail?: Maybe<Scalars['String']['output']>;
  registrationEmail?: Maybe<Scalars['String']['output']>;
  releasesOn: Array<Label>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ProUserPolicy = {
  __typename?: 'ProUserPolicy';
  accessPromoPool: Scalars['Boolean']['output'];
  update: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ProUserUpdate */
export type ProUserUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  proEmail?: InputMaybe<Scalars['String']['input']>;
  proUserId: Scalars['ID']['input'];
  promoEmail?: InputMaybe<Scalars['String']['input']>;
  registrationEmail?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ProUserUpdate. */
export type ProUserUpdatePayload = {
  __typename?: 'ProUserUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  proUser?: Maybe<ProUser>;
};

export type Profile = {
  __typename?: 'Profile';
  image?: Maybe<ArtistImage>;
  name: Scalars['String']['output'];
};

export enum PromoAccessStateEnum {
  FullAccess = 'FULL_ACCESS',
  LimitedAccess = 'LIMITED_ACCESS',
  None = 'NONE'
}

export type PromoPool = {
  __typename?: 'PromoPool';
  genres: Array<Genre>;
  labels: PromoSubscriptionPagination;
  releases: ReleaseConnection;
};

export type PromoPoolLabelsArgs = {
  filterBy?: InputMaybe<PromoSubscriptionFilterInput>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PromoSubscriptionOrderInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<LabelSubscriptionEnum>;
};

export type PromoPoolReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** An edge in a connection. */
export type PromoSubscriptionEdge = {
  __typename?: 'PromoSubscriptionEdge';
  addedAt: Scalars['ISO8601DateTime']['output'];
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  hasArchiveAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Label>;
  state: LabelSubscriptionEnum;
};

export type PromoSubscriptionFilterInput = {
  archiveAccess?: InputMaybe<BooleanFilterInput>;
  genreId?: InputMaybe<IdFilterInput>;
  labelId?: InputMaybe<IdFilterInput>;
  labelName?: InputMaybe<StringFilterInput>;
};

export type PromoSubscriptionOrderInput = {
  beatportFollowerCount?: InputMaybe<OrderDirectionEnum>;
  dateInvited?: InputMaybe<OrderDirectionEnum>;
  labelName?: InputMaybe<OrderDirectionEnum>;
  popularity?: InputMaybe<OrderDirectionEnum>;
  soundcloudFollowerCount?: InputMaybe<OrderDirectionEnum>;
};

export type PromoSubscriptionPagination = {
  __typename?: 'PromoSubscriptionPagination';
  edges: Array<PromoSubscriptionEdge>;
  pageInfo: PaginationInfo;
};

export type Query = {
  __typename?: 'Query';
  artist?: Maybe<Artist>;
  artists: ArtistConnection;
  discoveryMode: DiscoveryMode;
  genres: Array<Genre>;
  label?: Maybe<Label>;
  labels: LabelConnection;
  paymentProviders: Array<PaymentProvider>;
  proUser?: Maybe<ProUser>;
  radioMix?: Maybe<RadioMix>;
  radioMixes: RadioMixConnection;
  release?: Maybe<Release>;
  releaseLink?: Maybe<ReleaseLink>;
  releases: ReleaseConnection;
  spotifyPlaylistLabels?: Maybe<Array<SpotifyPlaylistLabel>>;
  spotifyPlaylistTracks?: Maybe<Array<SpotifyPlaylistTrack>>;
  spotifyPlaylists?: Maybe<Array<SpotifyPlaylist>>;
  statistics?: Maybe<Statistics>;
  user?: Maybe<User>;
  viewer?: Maybe<User>;
};

export type QueryArtistArgs = {
  id: Scalars['ID']['input'];
};

export type QueryArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLabelArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRadioMixArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRadioMixesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryReleaseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryReleaseLinkArgs = {
  id: Scalars['ID']['input'];
};

export type QueryReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  releaseMode?: InputMaybe<ReleaseModeEnum>;
};

export type QuerySpotifyPlaylistTracksArgs = {
  filterBy?: InputMaybe<SpotifyPlaylistTrackFilterInput>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type RadioMix = {
  __typename?: 'RadioMix';
  id: Scalars['ID']['output'];
  videoSettings?: Maybe<MixVideoSettings>;
};

/** The connection type for RadioMix. */
export type RadioMixConnection = {
  __typename?: 'RadioMixConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RadioMixEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<RadioMix>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RadioMixEdge = {
  __typename?: 'RadioMixEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RadioMix>;
};

export type Release = {
  __typename?: 'Release';
  artists: Array<Artist>;
  artistsConnection: ReleaseArtistConnection;
  beatportExclusivePeriod?: Maybe<Scalars['Int']['output']>;
  catalog?: Maybe<Scalars['String']['output']>;
  contributors: RoleConnection;
  coverArt?: Maybe<ImageAsset>;
  customForwarders?: Maybe<Array<ReleaseLinkCustomForwarder>>;
  customLinks: Array<CustomLink>;
  date?: Maybe<Scalars['ISO8601Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayAsVariousArtists: Scalars['Boolean']['output'];
  earliestReleaseDate?: Maybe<Scalars['ISO8601Date']['output']>;
  featuredArtists: Array<Artist>;
  genres: Array<Genre>;
  headlineArtists: Array<Artist>;
  id: Scalars['ID']['output'];
  inEditMode?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Label>;
  name?: Maybe<Scalars['String']['output']>;
  originalArtists: Array<Artist>;
  originalTracks: Array<Track>;
  permissions: ReleasePolicy;
  preorderDate?: Maybe<Scalars['ISO8601Date']['output']>;
  releaseMode?: Maybe<ReleaseModeEnum>;
  remixArtists: Array<Artist>;
  slug?: Maybe<Scalars['String']['output']>;
  statistics?: Maybe<ReleaseStatistics>;
  storeInfo: Array<StoreReleaseInfo>;
  tracks: Array<Track>;
  uid: Scalars['ID']['output'];
  viewer?: Maybe<ReleaseViewer>;
  visitor?: Maybe<ReleaseVisitor>;
};

export type ReleaseArtistsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReleaseContributorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReleaseCoverArtArgs = {
  size: CoverArtSizeEnum;
};

export type ReleaseOriginalTracksArgs = {
  blocking?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for Artist. */
export type ReleaseArtistConnection = {
  __typename?: 'ReleaseArtistConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReleaseArtistEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Artist>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReleaseArtistEdge = {
  __typename?: 'ReleaseArtistEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Artist>;
  role?: Maybe<RoleEnum>;
};

/** The connection type for Release. */
export type ReleaseConnection = {
  __typename?: 'ReleaseConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReleaseEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Release>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ReleaseEdge = {
  __typename?: 'ReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Release>;
};

export type ReleaseFilterInput = {
  hidden?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
};

export type ReleaseLink = {
  __typename?: 'ReleaseLink';
  forwarderInfo: Array<ForwarderInfo>;
  id?: Maybe<Scalars['ID']['output']>;
  release: Release;
  sharer?: Maybe<LinkSharer>;
  storeInfo: Array<StoreReleaseInfo>;
  streamingEnabled: Scalars['Boolean']['output'];
  uid: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

/** Autogenerated input type of ReleaseLinkCreate */
export type ReleaseLinkCreateInput = {
  artistId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId?: InputMaybe<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReleaseLinkCreate. */
export type ReleaseLinkCreatePayload = {
  __typename?: 'ReleaseLinkCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  link?: Maybe<ReleaseLink>;
};

export type ReleaseLinkCustomForwarder = {
  __typename?: 'ReleaseLinkCustomForwarder';
  id: Scalars['ID']['output'];
  linkText?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  storeName?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  visibilityStartDate?: Maybe<Scalars['ISO8601Date']['output']>;
};

/** Autogenerated input type of ReleaseLinkCustomForwarderCreate */
export type ReleaseLinkCustomForwarderCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId?: InputMaybe<Scalars['ID']['input']>;
  linkText?: InputMaybe<Scalars['String']['input']>;
  priority: Scalars['Int']['input'];
  releaseId?: InputMaybe<Scalars['ID']['input']>;
  storeName?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  visibilityStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Autogenerated return type of ReleaseLinkCustomForwarderCreate. */
export type ReleaseLinkCustomForwarderCreatePayload = {
  __typename?: 'ReleaseLinkCustomForwarderCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdForwarder?: Maybe<ReleaseLinkCustomForwarder>;
  customForwarders?: Maybe<Array<ReleaseLinkCustomForwarder>>;
  customizer?: Maybe<ReleaseLinkCustomizer>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of ReleaseLinkCustomForwarderDelete */
export type ReleaseLinkCustomForwarderDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  releaseLinkCustomForwarderId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReleaseLinkCustomForwarderDelete. */
export type ReleaseLinkCustomForwarderDeletePayload = {
  __typename?: 'ReleaseLinkCustomForwarderDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customForwarders?: Maybe<Array<ReleaseLinkCustomForwarder>>;
  customizer?: Maybe<ReleaseLinkCustomizer>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type ReleaseLinkCustomForwarderPriorityUpdate = {
  forwarderId: Scalars['ID']['input'];
  priority: Scalars['Int']['input'];
};

/** Autogenerated input type of ReleaseLinkCustomForwarderReorder */
export type ReleaseLinkCustomForwarderReorderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  updates: Array<ReleaseLinkCustomForwarderPriorityUpdate>;
};

/** Autogenerated return type of ReleaseLinkCustomForwarderReorder. */
export type ReleaseLinkCustomForwarderReorderPayload = {
  __typename?: 'ReleaseLinkCustomForwarderReorderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customForwarders?: Maybe<Array<ReleaseLinkCustomForwarder>>;
  customizer?: Maybe<ReleaseLinkCustomizer>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Autogenerated input type of ReleaseLinkCustomForwarderUpdate */
export type ReleaseLinkCustomForwarderUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  forwarderId: Scalars['ID']['input'];
  linkText?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  visibilityStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Autogenerated return type of ReleaseLinkCustomForwarderUpdate. */
export type ReleaseLinkCustomForwarderUpdatePayload = {
  __typename?: 'ReleaseLinkCustomForwarderUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customForwarders?: Maybe<Array<ReleaseLinkCustomForwarder>>;
  customizer?: Maybe<ReleaseLinkCustomizer>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

/** Label or Release that owns the release link customization */
export type ReleaseLinkCustomizer = Label | Release;

/** Autogenerated input type of ReleaseLinkOnboardingSeenUpdate */
export type ReleaseLinkOnboardingSeenUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  labelId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of ReleaseLinkOnboardingSeenUpdate. */
export type ReleaseLinkOnboardingSeenUpdatePayload = {
  __typename?: 'ReleaseLinkOnboardingSeenUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  viewer?: Maybe<User>;
};

export type ReleaseLinkSeedConfig = {
  artistSeeds: Array<ArtistSeed>;
  labelSeeds: Array<LabelSeed>;
  releaseSeeds: Array<ReleaseSeed>;
  userCount: Scalars['Int']['input'];
};

export type ReleaseLinkSeededData = {
  __typename?: 'ReleaseLinkSeededData';
  labels: Array<Label>;
  links: Array<ReleaseLink>;
  releases: Array<Release>;
  users: Array<User>;
};

export enum ReleaseLinkStateEnum {
  Disabled = 'DISABLED',
  EnabledForAll = 'ENABLED_FOR_ALL',
  EnabledForLabelManagers = 'ENABLED_FOR_LABEL_MANAGERS',
  None = 'NONE'
}

/** Autogenerated input type of ReleaseLinkStateUpdate */
export type ReleaseLinkStateUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configValue: ReleaseLinkStateEnum;
  labelId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReleaseLinkStateUpdate. */
export type ReleaseLinkStateUpdatePayload = {
  __typename?: 'ReleaseLinkStateUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  labelConfigs?: Maybe<LabelConfig>;
};

export enum ReleaseModeEnum {
  DjMix = 'DJ_MIX',
  Standard = 'STANDARD',
  Stem = 'STEM',
  StreamingSingle = 'STREAMING_SINGLE'
}

export type ReleaseOrderInput = {
  date?: InputMaybe<OrderDirectionEnum>;
};

export type ReleasePolicy = {
  __typename?: 'ReleasePolicy';
  download: Scalars['Boolean']['output'];
  viewFeedback: Scalars['Boolean']['output'];
};

export type ReleaseSeed = {
  artistRefs: Array<Scalars['ID']['input']>;
  labelRef: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  noDefaultTraits?: InputMaybe<Scalars['Boolean']['input']>;
  traits: Array<Scalars['String']['input']>;
};

export type ReleaseStatistics = {
  __typename?: 'ReleaseStatistics';
  preSaveEvents: EventGroup;
  sharingLinkEvents: EventGroup;
};

export enum ReleaseStatusEnum {
  NotAvailable = 'NOT_AVAILABLE',
  Preorder = 'PREORDER',
  Released = 'RELEASED',
  Unreleased = 'UNRELEASED'
}

export type ReleaseViewer = {
  __typename?: 'ReleaseViewer';
  id: Scalars['ID']['output'];
  isArtistMember: Scalars['Boolean']['output'];
  isLabelManager: Scalars['Boolean']['output'];
};

export type ReleaseVisitor = {
  __typename?: 'ReleaseVisitor';
  id: Scalars['ID']['output'];
  preSaves: Array<PreSave>;
};

/** Autogenerated input type of RequestMagicLink */
export type RequestMagicLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestMagicLink. */
export type RequestMagicLinkPayload = {
  __typename?: 'RequestMagicLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
};

export type Role = {
  __typename?: 'Role';
  artist?: Maybe<Artist>;
  id: Scalars['ID']['output'];
  role?: Maybe<RoleEnum>;
  track: Track;
};

/** The connection type for Role. */
export type RoleConnection = {
  __typename?: 'RoleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Role>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Role>;
};

export enum RoleEnum {
  Actor = 'ACTOR',
  Arranger = 'ARRANGER',
  AAndR = 'A_AND_R',
  Charity = 'CHARITY',
  Conductor = 'CONDUCTOR',
  Distributor = 'DISTRIBUTOR',
  Dj = 'DJ',
  FeaturedArtist = 'FEATURED_ARTIST',
  GraphicDesigner = 'GRAPHIC_DESIGNER',
  LabelManager = 'LABEL_MANAGER',
  Lyricist = 'LYRICIST',
  MasteringEngineer = 'MASTERING_ENGINEER',
  Orchestra = 'ORCHESTRA',
  OriginalArtist = 'ORIGINAL_ARTIST',
  Producer = 'PRODUCER',
  ReleasePromoter = 'RELEASE_PROMOTER',
  RemixArtist = 'REMIX_ARTIST',
  ThirdPartyLicensor = 'THIRD_PARTY_LICENSOR'
}

export type Show = {
  __typename?: 'Show';
  host: Artist;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  summary: Scalars['String']['output'];
};

export type SpotifyAlbum = {
  __typename?: 'SpotifyAlbum';
  images: Array<ImageAsset>;
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  popularity?: Maybe<Scalars['Int']['output']>;
  releaseDate?: Maybe<Scalars['ISO8601Date']['output']>;
  spotifyId: Scalars['ID']['output'];
  spotifyUrl: Scalars['String']['output'];
  upc: Scalars['String']['output'];
};

export type SpotifyArtist = {
  __typename?: 'SpotifyArtist';
  followers?: Maybe<Scalars['Int']['output']>;
  images: Array<ImageAsset>;
  name: Scalars['String']['output'];
  popularity?: Maybe<Scalars['Int']['output']>;
  spotifyId: Scalars['ID']['output'];
  spotifyUrl: Scalars['String']['output'];
};

export type SpotifyPlaylist = {
  __typename?: 'SpotifyPlaylist';
  addedAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  images: Array<ImageAsset>;
  lastSyncedAt: Scalars['ISO8601DateTime']['output'];
  lastUpdatedAt: Scalars['ISO8601DateTime']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<SpotifyPlaylistOwner>;
  snapshotId: Scalars['String']['output'];
  spotifyId: Scalars['ID']['output'];
  spotifyUrl: Scalars['String']['output'];
  tracks: Array<SpotifyPlaylistTrack>;
};

/** Autogenerated input type of SpotifyPlaylistAdd */
export type SpotifyPlaylistAddInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  spotifyId: Scalars['ID']['input'];
};

/** Autogenerated return type of SpotifyPlaylistAdd. */
export type SpotifyPlaylistAddPayload = {
  __typename?: 'SpotifyPlaylistAddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  playlist?: Maybe<SpotifyPlaylist>;
};

/** Autogenerated input type of SpotifyPlaylistDelete */
export type SpotifyPlaylistDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  spotifyId: Scalars['ID']['input'];
};

/** Autogenerated return type of SpotifyPlaylistDelete. */
export type SpotifyPlaylistDeletePayload = {
  __typename?: 'SpotifyPlaylistDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  playlist?: Maybe<SpotifyPlaylist>;
};

export type SpotifyPlaylistLabel = {
  __typename?: 'SpotifyPlaylistLabel';
  distributor?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mostRecentTrack: SpotifyPlaylistTrack;
  name?: Maybe<Scalars['String']['output']>;
  totalTracks?: Maybe<Scalars['Int']['output']>;
};

export type SpotifyPlaylistOwner = {
  __typename?: 'SpotifyPlaylistOwner';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  spotifyUrl: Scalars['String']['output'];
};

export type SpotifyPlaylistTrack = {
  __typename?: 'SpotifyPlaylistTrack';
  addedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  album?: Maybe<SpotifyAlbum>;
  artists: Array<SpotifyArtist>;
  distributor?: Maybe<Scalars['String']['output']>;
  durationMs?: Maybe<Scalars['Int']['output']>;
  isrc?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  popularity?: Maybe<Scalars['Int']['output']>;
  spotifyId: Scalars['ID']['output'];
  spotifyPlaylist: SpotifyPlaylist;
  spotifyUrl: Scalars['String']['output'];
  syncedAt: Scalars['ISO8601DateTime']['output'];
};

export type SpotifyPlaylistTrackFilterInput = {
  distributor?: InputMaybe<StringFilterInput>;
};

export type Statistics = {
  __typename?: 'Statistics';
  preSaveEvents: EventGroup;
  sharingLinkEvents: EventGroup;
  systemEvents: SystemEventGroup;
  userEvents: EventGroup;
};

export type StoreReleaseInfo = {
  __typename?: 'StoreReleaseInfo';
  name: Scalars['String']['output'];
  status: ReleaseStatusEnum;
  url?: Maybe<Scalars['String']['output']>;
};

export type StringFilterInput = {
  cont?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  notEq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  start?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SystemEventFilterInput = {
  createdAt?: InputMaybe<DateTimeFilterInput>;
  date?: InputMaybe<DateFilterInput>;
  event?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  time?: InputMaybe<DateTimeFilterInput>;
};

export type SystemEventGroup = {
  __typename?: 'SystemEventGroup';
  date?: Maybe<Array<GroupResultType>>;
  eventName?: Maybe<Array<GroupResultType>>;
  month?: Maybe<Array<GroupResultType>>;
  week?: Maybe<Array<GroupResultType>>;
  year?: Maybe<Array<GroupResultType>>;
};

export type SystemEventGroupEventNameArgs = {
  filterBy?: InputMaybe<SystemEventFilterInput>;
  orderBy?: InputMaybe<SystemEventOrderInput>;
};

export type SystemEventOrderInput = {
  createdAt?: InputMaybe<OrderDirectionEnum>;
  time?: InputMaybe<OrderDirectionEnum>;
};

/** Autogenerated input type of TestDatabaseReleaseLinkSeed */
export type TestDatabaseReleaseLinkSeedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  config: ReleaseLinkSeedConfig;
};

/** Autogenerated return type of TestDatabaseReleaseLinkSeed. */
export type TestDatabaseReleaseLinkSeedPayload = {
  __typename?: 'TestDatabaseReleaseLinkSeedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  seeded?: Maybe<ReleaseLinkSeededData>;
};

export type Track = {
  __typename?: 'Track';
  artists: Array<Artist>;
  asset?: Maybe<AudioAsset>;
  featuredArtists: Array<Artist>;
  id: Scalars['ID']['output'];
  originalArtists: Array<Artist>;
  permissions: TrackPolicy;
  reaction?: Maybe<TrackReaction>;
  release?: Maybe<Release>;
  remixArtists: Array<Artist>;
  title?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  waveformUrl?: Maybe<Scalars['String']['output']>;
};

export type TrackAssetArgs = {
  quality: AudioQualityEnum;
};

export type TrackPolicy = {
  __typename?: 'TrackPolicy';
  downloadLossless: Scalars['Boolean']['output'];
  downloadMp3: Scalars['Boolean']['output'];
};

export type TrackReaction = {
  __typename?: 'TrackReaction';
  comment?: Maybe<Scalars['String']['output']>;
  dismissedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  downloadedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  listenedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  reactedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type User = {
  __typename?: 'User';
  artists: Array<Artist>;
  availablePaymentMethods: Array<PaymentMethod>;
  canEditPayment: Scalars['Boolean']['output'];
  communicationChannels: Array<CommunicationChannel>;
  configs: UserConfig;
  configuredPaymentMethods: Array<PaymentMethodConfig>;
  connections: Array<UserConnection>;
  hasPaymentPending: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isImpersonated: Scalars['Boolean']['output'];
  managedLabels: Array<Label>;
  notificationConfigs: Array<NotificationConfig>;
  paymentMethods: PaymentMethodPagination;
  permissions: UserPolicy;
  privacyPolicyConsents: Array<PrivacyPolicyConsent>;
  proUser?: Maybe<ProUser>;
  promoPool?: Maybe<PromoPool>;
  refreshToken: Scalars['ID']['output'];
  releasesOn: Array<Label>;
  roles: UserRoles;
  /** @deprecated Use `configs` instead. */
  settings: Array<UserSetting>;
  shows: Array<Show>;
  token: Scalars['ID']['output'];
  username: Scalars['String']['output'];
};

export type UserPaymentMethodsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type UserConfig = {
  __typename?: 'UserConfig';
  autoDownload?: Maybe<Scalars['String']['output']>;
  downloadFormat?: Maybe<Scalars['String']['output']>;
  downloadLocation?: Maybe<Scalars['String']['output']>;
  hasSeenDiscoveryModeOnboarding: Scalars['Boolean']['output'];
  hasSeenReleaseLinkOnboarding: Scalars['Boolean']['output'];
  promoLabelInviteNotification?: Maybe<Scalars['String']['output']>;
  promoTrackNotification?: Maybe<Scalars['String']['output']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: ConnectionTypeEnum;
};

export type UserPolicy = {
  __typename?: 'UserPolicy';
  accessPromoPool: Scalars['Boolean']['output'];
  proUser: Scalars['Boolean']['output'];
};

export type UserRoles = {
  __typename?: 'UserRoles';
  admin: Scalars['Boolean']['output'];
  artist: Scalars['Boolean']['output'];
  curator: Scalars['Boolean']['output'];
  labelManager: Scalars['Boolean']['output'];
  showHost: Scalars['Boolean']['output'];
};

export type UserSetting = {
  __typename?: 'UserSetting';
  enabled: Scalars['Boolean']['output'];
  settingType: UserSettingTypeEnum;
  value?: Maybe<Scalars['String']['output']>;
};

export enum UserSettingTypeEnum {
  AutoDownload = 'AUTO_DOWNLOAD',
  DiscoveryModeOnboardingSeen = 'DISCOVERY_MODE_ONBOARDING_SEEN',
  DownloadFormat = 'DOWNLOAD_FORMAT',
  DownloadLocation = 'DOWNLOAD_LOCATION',
  PromoLabelInviteNotification = 'PROMO_LABEL_INVITE_NOTIFICATION',
  PromoTrackNotification = 'PROMO_TRACK_NOTIFICATION',
  ReleaseLinkOnboardingSeen = 'RELEASE_LINK_ONBOARDING_SEEN'
}

/** Autogenerated input type of UserSettingUpdate */
export type UserSettingUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  settingType: UserSettingTypeEnum;
  userId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserSettingUpdate. */
export type UserSettingUpdatePayload = {
  __typename?: 'UserSettingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Array<Error>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  userSetting?: Maybe<UserSetting>;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UserSettingUpdateInput;
}>;

export type UpdateUserSettingsMutation = {
  __typename?: 'Mutation';
  userSettingUpdate?: {
    __typename?: 'UserSettingUpdatePayload';
    errorDetails?: Array<{ __typename?: 'Error'; message: string }> | null;
    userSetting?: {
      __typename?: 'UserSetting';
      enabled: boolean;
      settingType: UserSettingTypeEnum;
      value?: string | null;
    } | null;
  } | null;
};

export type DiscoveryModeOnboardingSeenUpdateMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DiscoveryModeOnboardingSeenUpdateMutation = {
  __typename?: 'Mutation';
  discoveryModeOnboardingSeenUpdate?: {
    __typename?: 'DiscoveryModeOnboardingSeenUpdatePayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type DiscoveryModeOptInOutMutationVariables = Exact<{
  artistId?: InputMaybe<Scalars['ID']['input']>;
  labelId?: InputMaybe<Scalars['ID']['input']>;
  enabled: Scalars['Boolean']['input'];
}>;

export type DiscoveryModeOptInOutMutation = {
  __typename?: 'Mutation';
  discoveryModeOptInOut?: {
    __typename?: 'DiscoveryModeOptInOutPayload';
    enabled?: boolean | null;
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type EnabledDisableTrackMutationVariables = Exact<{
  input: DiscoveryModeEnableDisableTrackInput;
}>;

export type EnabledDisableTrackMutation = {
  __typename?: 'Mutation';
  discoveryModeEnableDisableTrack?: {
    __typename?: 'DiscoveryModeEnableDisableTrackPayload';
    errors?: any | null;
    discoveryModeTrack?: {
      __typename?: 'DiscoveryModeTrack';
      id: string;
      enabled: boolean;
      track: {
        __typename?: 'Track';
        id: string;
        title?: string | null;
        version?: string | null;
        originalArtists: Array<{
          __typename?: 'Artist';
          id: string;
          name?: string | null;
        }>;
        release?: {
          __typename?: 'Release';
          id: string;
          coverArt?: { __typename?: 'ImageAsset'; url: string } | null;
        } | null;
      };
    } | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type AddChannelMutationVariables = Exact<{
  input: CommunicationChannelAddInput;
}>;

export type AddChannelMutation = {
  __typename?: 'Mutation';
  communicationChannelAdd?: {
    __typename?: 'CommunicationChannelAddPayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    channel?: {
      __typename?: 'CommunicationChannel';
      id: string;
      channel: CommunicationChannelEnum;
      emailField?: EmailFieldEnum | null;
      isVerified: boolean;
      value?: string | null;
    } | null;
  } | null;
};

export type GetUserCommunicationChannelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserCommunicationChannelsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    communicationChannels: Array<{
      __typename?: 'CommunicationChannel';
      id: string;
      channel: CommunicationChannelEnum;
      value?: string | null;
      isVerified: boolean;
    }>;
  } | null;
};

export type DeliverOtpMutationVariables = Exact<{
  input: CommunicationChannelDeliverOtpInput;
}>;

export type DeliverOtpMutation = {
  __typename?: 'Mutation';
  communicationChannelDeliverOtp?: {
    __typename?: 'CommunicationChannelDeliverOtpPayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    channel?: {
      __typename?: 'CommunicationChannel';
      id: string;
      channel: CommunicationChannelEnum;
      emailField?: EmailFieldEnum | null;
      isVerified: boolean;
      value?: string | null;
    } | null;
  } | null;
};

export type VerifyOtpMutationVariables = Exact<{
  input: CommunicationChannelVerifyOtpInput;
}>;

export type VerifyOtpMutation = {
  __typename?: 'Mutation';
  communicationChannelVerifyOtp?: {
    __typename?: 'CommunicationChannelVerifyOtpPayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    channel?: {
      __typename?: 'CommunicationChannel';
      id: string;
      channel: CommunicationChannelEnum;
      emailField?: EmailFieldEnum | null;
      isVerified: boolean;
      value?: string | null;
    } | null;
  } | null;
};

export type DiscoveryModeConfigsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type DiscoveryModeConfigsQueryQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    managedLabels: Array<{
      __typename?: 'Label';
      id: string;
      name?: string | null;
      configs?: { __typename?: 'LabelConfig'; hasDiscoveryModeEnabled: boolean } | null;
      logo?: { __typename?: 'ImageAsset'; url: string } | null;
    }>;
    artists: Array<{
      __typename?: 'Artist';
      id: string;
      name?: string | null;
      image?: { __typename?: 'ArtistImage'; url?: string | null } | null;
      configs?: { __typename?: 'ArtistConfig'; hasDiscoveryModeEnabled: boolean } | null;
    }>;
    configs: { __typename?: 'UserConfig'; hasSeenDiscoveryModeOnboarding: boolean };
  } | null;
};

export type EligibleTracksFieldsFragment = {
  __typename?: 'DiscoveryModeTrack';
  enabled: boolean;
  approved: boolean;
  id: string;
  intentRate?: number | null;
  listeners?: number | null;
  listenersLift?: number | null;
  saves?: number | null;
  spotifyRecommend: boolean;
  streams?: number | null;
  streamsLift?: number | null;
  enabledEntities: Array<{
    __typename?: 'Entity';
    id: string;
    name: string;
    type: string;
    slug: string;
  }>;
  track: {
    __typename?: 'Track';
    id: string;
    title?: string | null;
    version?: string | null;
    originalArtists: Array<{
      __typename?: 'Artist';
      id: string;
      name?: string | null;
      image?: { __typename?: 'ArtistImage'; url?: string | null } | null;
    }>;
    featuredArtists: Array<{
      __typename?: 'Artist';
      id: string;
      name?: string | null;
      image?: { __typename?: 'ArtistImage'; url?: string | null } | null;
    }>;
    remixArtists: Array<{
      __typename?: 'Artist';
      id: string;
      name?: string | null;
      image?: { __typename?: 'ArtistImage'; url?: string | null } | null;
    }>;
    release?: {
      __typename?: 'Release';
      id: string;
      coverArt?: { __typename?: 'ImageAsset'; url: string } | null;
      label?: {
        __typename?: 'Label';
        id: string;
        name?: string | null;
        logo?: { __typename?: 'ImageAsset'; url: string } | null;
      } | null;
    } | null;
  };
} & { ' $fragmentName'?: 'EligibleTracksFieldsFragment' };

export type DiscoveryModeQueryVariables = Exact<{
  filter?: InputMaybe<DiscoveryModeTrackFilterInput>;
}>;

export type DiscoveryModeQuery = {
  __typename?: 'Query';
  discoveryMode: {
    __typename?: 'DiscoveryMode';
    nextSchedulingStartsAt: any;
    runningCampaign: {
      __typename?: 'DiscoveryModeCampaign';
      id: string;
      title: string;
      startDate: any;
      endDate: any;
      state: DiscoveryModeCampaignStateEnum;
      schedulingStartsAt: any;
      schedulingEndsAt: any;
      eligibleTracks: Array<
        { __typename?: 'DiscoveryModeTrack' } & {
          ' $fragmentRefs'?: {
            EligibleTracksFieldsFragment: EligibleTracksFieldsFragment;
          };
        }
      >;
    };
    upcomingCampaign: {
      __typename?: 'DiscoveryModeCampaign';
      id: string;
      title: string;
      startDate: any;
      endDate: any;
      state: DiscoveryModeCampaignStateEnum;
      schedulingStartsAt: any;
      schedulingEndsAt: any;
      eligibleTracks: Array<
        { __typename?: 'DiscoveryModeTrack' } & {
          ' $fragmentRefs'?: {
            EligibleTracksFieldsFragment: EligibleTracksFieldsFragment;
          };
        }
      >;
    };
  };
};

export type RemoveSubscriberMutationVariables = Exact<{
  labelId: Scalars['ID']['input'];
  artistId: Scalars['ID']['input'];
}>;

export type RemoveSubscriberMutation = {
  __typename?: 'Mutation';
  labelSubscriberRemove?: {
    __typename?: 'LabelSubscriberRemovePayload';
    artist?: { __typename?: 'Artist'; id: string } | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      path?: Array<string> | null;
      message: string;
    }> | null;
  } | null;
};

export type InviteSubscriberMutationVariables = Exact<{
  labelId: Scalars['ID']['input'];
  artistId: Scalars['ID']['input'];
}>;

export type InviteSubscriberMutation = {
  __typename?: 'Mutation';
  labelSubscriberInvite?: {
    __typename?: 'LabelSubscriberInvitePayload';
    artist?: { __typename?: 'Artist'; id: string } | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      path?: Array<string> | null;
      message: string;
    }> | null;
  } | null;
};

export type GetLabelAndViewerQueryVariables = Exact<{
  labelId: Scalars['ID']['input'];
}>;

export type GetLabelAndViewerQuery = {
  __typename?: 'Query';
  label?: {
    __typename?: 'Label';
    id: string;
    name?: string | null;
    activeEndYear?: number | null;
    activeStartYear?: number | null;
    promoAccessState?: PromoAccessStateEnum | null;
    hasPromoAccess?: boolean | null;
    description?: string | null;
    slug?: string | null;
    viewer?: {
      __typename?: 'LabelViewer';
      id: string;
      hasReleasesOnLabel: boolean;
      isLabelManager: boolean;
    } | null;
    genres: Array<{ __typename?: 'Genre'; name: string }>;
    logo?: { __typename?: 'ImageAsset'; url: string } | null;
  } | null;
  viewer?: {
    __typename?: 'User';
    id: string;
    promoPool?: {
      __typename?: 'PromoPool';
      labels: {
        __typename?: 'PromoSubscriptionPagination';
        edges: Array<{
          __typename?: 'PromoSubscriptionEdge';
          id: string;
          addedAt: any;
          state: LabelSubscriptionEnum;
          node?: { __typename?: 'Label'; id: string; name?: string | null } | null;
        }>;
      };
    } | null;
  } | null;
};

export type GetReleaseDspInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetReleaseDspInfoQuery = {
  __typename?: 'Query';
  release?: {
    __typename?: 'Release';
    storeInfo: Array<{
      __typename: 'StoreReleaseInfo';
      name: string;
      url?: string | null;
      status: ReleaseStatusEnum;
    }>;
  } | null;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    username: string;
    token: string;
    managedLabels: Array<{ __typename?: 'Label'; id: string; name?: string | null }>;
    privacyPolicyConsents: Array<{
      __typename?: 'PrivacyPolicyConsent';
      id: string;
      version: string;
      consentedAt: any;
    }>;
    roles: {
      __typename?: 'UserRoles';
      admin: boolean;
      artist: boolean;
      curator: boolean;
      labelManager: boolean;
      showHost: boolean;
    };
    permissions: {
      __typename?: 'UserPolicy';
      accessPromoPool: boolean;
      proUser: boolean;
    };
    settings: Array<{
      __typename?: 'UserSetting';
      enabled: boolean;
      settingType: UserSettingTypeEnum;
      value?: string | null;
    }>;
  } | null;
};

export type PrivacyPolicyConsentMutationVariables = Exact<{
  version: Scalars['ID']['input'];
}>;

export type PrivacyPolicyConsentMutation = {
  __typename?: 'Mutation';
  privacyPolicyConsent?: {
    __typename?: 'PrivacyPolicyConsentPayload';
    errors?: any | null;
    privacyPolicyConsents?: Array<{
      __typename?: 'PrivacyPolicyConsent';
      id: string;
      version: string;
    }> | null;
  } | null;
};

export type UpdateReleaseLinkStateMutationVariables = Exact<{
  input: ReleaseLinkStateUpdateInput;
}>;

export type UpdateReleaseLinkStateMutation = {
  __typename?: 'Mutation';
  releaseLinkStateUpdate?: {
    __typename?: 'ReleaseLinkStateUpdatePayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    labelConfigs?: {
      __typename?: 'LabelConfig';
      releaseLinkState?: ReleaseLinkStateEnum | null;
    } | null;
  } | null;
};

export type ReleaseLinkNotificationConfigUpdateMutationVariables = Exact<{
  input: NotificationConfigUpdateInput;
}>;

export type ReleaseLinkNotificationConfigUpdateMutation = {
  __typename?: 'Mutation';
  releaseLinkNotificationConfigUpdate?: {
    __typename?: 'NotificationConfigUpdatePayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    config?: { __typename?: 'NotificationConfig'; id: string } | null;
  } | null;
};

export type CommunicationChannelAddMutationVariables = Exact<{
  input: CommunicationChannelAddInput;
}>;

export type CommunicationChannelAddMutation = {
  __typename?: 'Mutation';
  communicationChannelAdd?: {
    __typename?: 'CommunicationChannelAddPayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    channel?: {
      __typename?: 'CommunicationChannel';
      id: string;
      channel: CommunicationChannelEnum;
      isVerified: boolean;
      value?: string | null;
    } | null;
  } | null;
};

export type CommunicationChannelDeliverOtpMutationVariables = Exact<{
  input: CommunicationChannelDeliverOtpInput;
}>;

export type CommunicationChannelDeliverOtpMutation = {
  __typename?: 'Mutation';
  communicationChannelDeliverOtp?: {
    __typename?: 'CommunicationChannelDeliverOtpPayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    channel?: {
      __typename?: 'CommunicationChannel';
      id: string;
      channel: CommunicationChannelEnum;
      isVerified: boolean;
      value?: string | null;
    } | null;
  } | null;
};

export type ViewerNotificationConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerNotificationConfigsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    proUser?: { __typename?: 'ProUser'; id: string; country?: string | null } | null;
    notificationConfigs: Array<{
      __typename?: 'NotificationConfig';
      feature: NotificationConfigFeatureEnum;
      enabled: boolean;
      artist?: { __typename?: 'Artist'; id: string; name?: string | null } | null;
      label?: {
        __typename?: 'Label';
        id: string;
        name?: string | null;
        configs?: {
          __typename?: 'LabelConfig';
          releaseLinkFeatureEnabled: boolean;
          releaseLinkState?: ReleaseLinkStateEnum | null;
        } | null;
      } | null;
      communicationChannel?: {
        __typename?: 'CommunicationChannel';
        id: string;
        channel: CommunicationChannelEnum;
        value?: string | null;
        isVerified: boolean;
        emailField?: EmailFieldEnum | null;
      } | null;
    }>;
    communicationChannels: Array<{
      __typename?: 'CommunicationChannel';
      id: string;
      channel: CommunicationChannelEnum;
      emailField?: EmailFieldEnum | null;
      isVerified: boolean;
      value?: string | null;
    }>;
  } | null;
};

export type GetPaymentSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaymentSettingsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    hasPaymentPending: boolean;
    configuredPaymentMethods: Array<{
      __typename?: 'PaymentMethodConfig';
      id: string;
      isValid: boolean;
      default: boolean;
      token?: string | null;
      address?: string | null;
      network?: CryptoNetworkEnum | null;
      overrideRestriction: boolean;
      needsConfirmation: boolean;
      preferredPaymentProvider?: { __typename?: 'PaymentProvider'; id: string } | null;
      paymentMethod: {
        __typename?: 'PaymentMethod';
        id: string;
        name: string;
        status: string;
        automatic: boolean;
      };
    }>;
    paymentMethods: {
      __typename?: 'PaymentMethodPagination';
      edges: Array<{
        __typename?: 'PaymentMethodEdge';
        enabled?: boolean | null;
        message?: string | null;
        node?: {
          __typename?: 'PaymentMethod';
          id: string;
          name: string;
          status: string;
          allowOverride: boolean;
          automatic: boolean;
          needsConfirmation?: boolean | null;
          paymentProviders: Array<{
            __typename?: 'PaymentProvider';
            id: string;
            name: string;
          }>;
        } | null;
      }>;
    };
  } | null;
};

export type ReleaseLinkOnboardingStatusQueryVariables = Exact<{
  labelId: Scalars['ID']['input'];
}>;

export type ReleaseLinkOnboardingStatusQuery = {
  __typename?: 'Query';
  label?: {
    __typename?: 'Label';
    id: string;
    name?: string | null;
    viewer?: {
      __typename?: 'LabelViewer';
      shouldDisplayReleaseLinkOnboarding: boolean;
    } | null;
  } | null;
};

export type ReleaseLinkOnboardingSeenMutationVariables = Exact<{
  input: ReleaseLinkOnboardingSeenUpdateInput;
}>;

export type ReleaseLinkOnboardingSeenMutation = {
  __typename?: 'Mutation';
  releaseLinkOnboardingSeenUpdate?: {
    __typename?: 'ReleaseLinkOnboardingSeenUpdatePayload';
    errors?: any | null;
    errorDetails?: Array<{
      __typename?: 'Error';
      message: string;
      path?: Array<string> | null;
    }> | null;
    viewer?: {
      __typename?: 'User';
      id: string;
      configs: { __typename?: 'UserConfig'; hasSeenReleaseLinkOnboarding: boolean };
    } | null;
  } | null;
};

export type ReleaseArtistsQueryVariables = Exact<{
  releaseId: Scalars['ID']['input'];
}>;

export type ReleaseArtistsQuery = {
  __typename?: 'Query';
  release?: {
    __typename?: 'Release';
    headlineArtists: Array<{ __typename?: 'Artist'; id: string; name?: string | null }>;
  } | null;
};

export type PromoLabelInvitesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  filterBy?: InputMaybe<PromoSubscriptionFilterInput>;
  orderBy?: InputMaybe<PromoSubscriptionOrderInput>;
  status?: InputMaybe<LabelSubscriptionEnum>;
}>;

export type PromoLabelInvitesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'User';
    id: string;
    promoPool?: {
      __typename?: 'PromoPool';
      labels: {
        __typename?: 'PromoSubscriptionPagination';
        pageInfo: {
          __typename: 'PaginationInfo';
          pageCount: number;
          nodeCount: number;
          hasNextPage: boolean;
          hasPreviousPage: boolean;
        };
        edges: Array<{
          __typename: 'PromoSubscriptionEdge';
          id: string;
          addedAt: any;
          hasArchiveAccess: boolean;
          state: LabelSubscriptionEnum;
          node?: {
            __typename: 'Label';
            id: string;
            name?: string | null;
            slug?: string | null;
            beatportFollowerCount?: number | null;
            soundcloudFollowerCount?: number | null;
            logo?: { __typename?: 'ImageAsset'; url: string } | null;
            topArtists: Array<{
              __typename?: 'Artist';
              id: string;
              name?: string | null;
            }>;
            genres: Array<{ __typename?: 'Genre'; id: string; name: string }>;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type ArchiveAccessFieldsFragment = {
  __typename?: 'ArtistLabelSubscription';
  archiveAccessExpiresAt?: any | null;
  archiveAccessGrantedAt?: any | null;
  hasArchiveAccess: boolean;
  label: { __typename?: 'Label'; id: string; name?: string | null };
} & { ' $fragmentName'?: 'ArchiveAccessFieldsFragment' };

export type GrantArchiveAccessMutationVariables = Exact<{
  labelId: Scalars['ID']['input'];
  artistId: Scalars['ID']['input'];
}>;

export type GrantArchiveAccessMutation = {
  __typename?: 'Mutation';
  archiveAccessGrant?: {
    __typename?: 'ArchiveAccessGrantPayload';
    errors?: any | null;
    artistLabelSubscription?:
      | ({ __typename?: 'ArtistLabelSubscription' } & {
          ' $fragmentRefs'?: { ArchiveAccessFieldsFragment: ArchiveAccessFieldsFragment };
        })
      | null;
  } | null;
};

export type RenewArchiveAccessMutationVariables = Exact<{
  labelId: Scalars['ID']['input'];
  artistId: Scalars['ID']['input'];
}>;

export type RenewArchiveAccessMutation = {
  __typename?: 'Mutation';
  archiveAccessRenew?: {
    __typename?: 'ArchiveAccessRenewPayload';
    errors?: any | null;
    artistLabelSubscription?:
      | ({ __typename?: 'ArtistLabelSubscription' } & {
          ' $fragmentRefs'?: { ArchiveAccessFieldsFragment: ArchiveAccessFieldsFragment };
        })
      | null;
  } | null;
};

export type RevokeArchiveAccessMutationVariables = Exact<{
  labelId: Scalars['ID']['input'];
  artistId: Scalars['ID']['input'];
}>;

export type RevokeArchiveAccessMutation = {
  __typename?: 'Mutation';
  archiveAccessRevoke?: {
    __typename?: 'ArchiveAccessRevokePayload';
    errors?: any | null;
    artistLabelSubscription?:
      | ({ __typename?: 'ArtistLabelSubscription' } & {
          ' $fragmentRefs'?: { ArchiveAccessFieldsFragment: ArchiveAccessFieldsFragment };
        })
      | null;
  } | null;
};

export type GetLabelSubscribersQueryVariables = Exact<{
  labelId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<LabelSubscriberOrderInput>;
  filterBy?: InputMaybe<LabelSubscriberFilterInput>;
}>;

export type GetLabelSubscribersQuery = {
  __typename?: 'Query';
  label?: {
    __typename?: 'Label';
    id: string;
    subscribers?: {
      __typename?: 'LabelSubscriberPagination';
      pageInfo: {
        __typename?: 'PaginationInfo';
        page: number;
        pageCount: number;
        nodeCount: number;
        perPage: number;
        hasNextPage: boolean;
      };
      edges: Array<{
        __typename?: 'LabelSubscriberEdge';
        id: string;
        addedAt?: any | null;
        lastReactedAt?: any | null;
        hasArchiveAccess: boolean;
        archiveAccessExpiresAt?: any | null;
        archiveAccessGrantedAt?: any | null;
        averageRating: number;
        hasPromoAccess: boolean;
        isFollowing: boolean;
        node?: {
          __typename?: 'Artist';
          id: string;
          name?: string | null;
          slug?: string | null;
          spotifyPopularity: number;
          image?: { __typename?: 'ArtistImage'; url?: string | null } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GetReleaseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetReleaseQuery = {
  __typename?: 'Query';
  release?:
    | ({
        __typename?: 'Release';
        uid: string;
        id: string;
        name?: string | null;
        description?: string | null;
        date?: any | null;
        slug?: string | null;
        preorderDate?: any | null;
        headlineArtists: Array<{
          __typename?: 'Artist';
          id: string;
          name?: string | null;
          slug?: string | null;
        }>;
        permissions: { __typename?: 'ReleasePolicy'; viewFeedback: boolean };
        label?: {
          __typename?: 'Label';
          id: string;
          name?: string | null;
          slug?: string | null;
        } | null;
        coverArt?: { __typename?: 'ImageAsset'; url: string } | null;
        viewer?: {
          __typename?: 'ReleaseViewer';
          isLabelManager: boolean;
          isArtistMember: boolean;
        } | null;
      } & {
        ' $fragmentRefs'?: {
          ReleaseDetailsFieldsFragment: ReleaseDetailsFieldsFragment;
          MobileButtonsReleaseFieldsFragment: MobileButtonsReleaseFieldsFragment;
        };
      })
    | null;
};

export type ReleaseDetailsFieldsFragment = {
  __typename?: 'Release';
  date?: any | null;
  description?: string | null;
  label?: { __typename?: 'Label'; name?: string | null; slug?: string | null } | null;
  genres: Array<{ __typename?: 'Genre'; name: string; id: string; occurrences: number }>;
  storeInfo: Array<{
    __typename?: 'StoreReleaseInfo';
    name: string;
    url?: string | null;
    status: ReleaseStatusEnum;
  }>;
} & { ' $fragmentName'?: 'ReleaseDetailsFieldsFragment' };

export type MobileButtonsReleaseFieldsFragment = {
  __typename?: 'Release';
  id: string;
  date?: any | null;
  label?: { __typename?: 'Label'; name?: string | null; slug?: string | null } | null;
} & { ' $fragmentName'?: 'MobileButtonsReleaseFieldsFragment' };

export const EligibleTracksFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EligibleTracksFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DiscoveryModeTrack' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listeners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listenersLift' } },
          { kind: 'Field', name: { kind: 'Name', value: 'saves' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spotifyRecommend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streams' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamsLift' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledEntities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featuredArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remixArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'release' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coverArt' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'EnumValue', value: 'SMALL' }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'SMALL' }
                                }
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EligibleTracksFieldsFragment, unknown>;
export const ArchiveAccessFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArchiveAccessFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArtistLabelSubscription' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessExpiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessGrantedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasArchiveAccess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ArchiveAccessFieldsFragment, unknown>;
export const ReleaseDetailsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReleaseDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Release' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'genres' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occurrences' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ReleaseDetailsFieldsFragment, unknown>;
export const MobileButtonsReleaseFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MobileButtonsReleaseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Release' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MobileButtonsReleaseFieldsFragment, unknown>;
export const UpdateUserSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserSettingUpdateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userSettingUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userSetting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'settingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const DiscoveryModeOnboardingSeenUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'discoveryModeOnboardingSeenUpdate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoveryModeOnboardingSeenUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'ObjectValue', fields: [] }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DiscoveryModeOnboardingSeenUpdateMutation,
  DiscoveryModeOnboardingSeenUpdateMutationVariables
>;
export const DiscoveryModeOptInOutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'discoveryModeOptInOut' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'enabled' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoveryModeOptInOut' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' }
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' }
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'enabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'enabled' }
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DiscoveryModeOptInOutMutation,
  DiscoveryModeOptInOutMutationVariables
>;
export const EnabledDisableTrackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EnabledDisableTrack' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DiscoveryModeEnableDisableTrackInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoveryModeEnableDisableTrack' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discoveryModeTrack' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'track' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalArtists' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'release' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverArt' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'SMALL' }
                                      }
                                    ],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  EnabledDisableTrackMutation,
  EnabledDisableTrackMutationVariables
>;
export const AddChannelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addChannel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommunicationChannelAddInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationChannelAdd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddChannelMutation, AddChannelMutationVariables>;
export const GetUserCommunicationChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserCommunicationChannels' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communicationChannels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetUserCommunicationChannelsQuery,
  GetUserCommunicationChannelsQueryVariables
>;
export const DeliverOtpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deliverOtp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommunicationChannelDeliverOtpInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationChannelDeliverOtp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeliverOtpMutation, DeliverOtpMutationVariables>;
export const VerifyOtpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verifyOtp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommunicationChannelVerifyOtpInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationChannelVerifyOtp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const DiscoveryModeConfigsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DiscoveryModeConfigsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managedLabels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasDiscoveryModeEnabled' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logo' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'EnumValue', value: 'SMALL' }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasDiscoveryModeEnabled' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasSeenDiscoveryModeOnboarding' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DiscoveryModeConfigsQueryQuery,
  DiscoveryModeConfigsQueryQueryVariables
>;
export const DiscoveryModeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DiscoveryMode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DiscoveryModeTrackFilterInput' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'discoveryMode' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextSchedulingStartsAt' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'runningCampaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedulingStartsAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedulingEndsAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eligibleTracks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'EligibleTracksFields' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upcomingCampaign' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedulingStartsAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'schedulingEndsAt' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eligibleTracks' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filterBy' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filter' }
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'EligibleTracksFields' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EligibleTracksFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DiscoveryModeTrack' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approved' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listeners' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listenersLift' } },
          { kind: 'Field', name: { kind: 'Name', value: 'saves' } },
          { kind: 'Field', name: { kind: 'Name', value: 'spotifyRecommend' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streams' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streamsLift' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enabledEntities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'originalArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featuredArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remixArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'release' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coverArt' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'EnumValue', value: 'SMALL' }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'SMALL' }
                                }
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DiscoveryModeQuery, DiscoveryModeQueryVariables>;
export const RemoveSubscriberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeSubscriber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labelSubscriberRemove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' }
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' }
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RemoveSubscriberMutation, RemoveSubscriberMutationVariables>;
export const InviteSubscriberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'inviteSubscriber' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'labelSubscriberInvite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' }
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' }
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artist' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<InviteSubscriberMutation, InviteSubscriberMutationVariables>;
export const GetLabelAndViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLabelAndViewer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeEndYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activeStartYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'promoAccessState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPromoAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasReleasesOnLabel' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isLabelManager' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'genres' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logo' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'EnumValue', value: 'MEDIUM' }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promoPool' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filterBy' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'labelId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'eq' },
                                        value: {
                                          kind: 'Variable',
                                          name: { kind: 'Name', value: 'labelId' }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addedAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLabelAndViewerQuery, GetLabelAndViewerQueryVariables>;
export const GetReleaseDspInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getReleaseDSPInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'release' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storeInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetReleaseDspInfoQuery, GetReleaseDspInfoQueryVariables>;
export const GetCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'managedLabels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'privacyPolicyConsents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'consentedAt' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'admin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'artist' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'curator' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'labelManager' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showHost' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'accessPromoPool' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'proUser' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'settingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const PrivacyPolicyConsentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'privacyPolicyConsent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'version' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyPolicyConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'version' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'version' }
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'privacyPolicyConsents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  PrivacyPolicyConsentMutation,
  PrivacyPolicyConsentMutationVariables
>;
export const UpdateReleaseLinkStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateReleaseLinkState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReleaseLinkStateUpdateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseLinkStateUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'labelConfigs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'releaseLinkState' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateReleaseLinkStateMutation,
  UpdateReleaseLinkStateMutationVariables
>;
export const ReleaseLinkNotificationConfigUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'releaseLinkNotificationConfigUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NotificationConfigUpdateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseLinkNotificationConfigUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReleaseLinkNotificationConfigUpdateMutation,
  ReleaseLinkNotificationConfigUpdateMutationVariables
>;
export const CommunicationChannelAddDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'communicationChannelAdd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommunicationChannelAddInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationChannelAdd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CommunicationChannelAddMutation,
  CommunicationChannelAddMutationVariables
>;
export const CommunicationChannelDeliverOtpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'communicationChannelDeliverOtp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommunicationChannelDeliverOtpInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'communicationChannelDeliverOtp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CommunicationChannelDeliverOtpMutation,
  CommunicationChannelDeliverOtpMutationVariables
>;
export const ViewerNotificationConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'viewerNotificationConfigs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'proUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'country' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notificationConfigs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'artist' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'configs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'releaseLinkFeatureEnabled'
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'releaseLinkState' }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'communicationChannel' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isVerified' }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailField' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'feature' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'communicationChannels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'channel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'emailField' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ViewerNotificationConfigsQuery,
  ViewerNotificationConfigsQueryVariables
>;
export const GetPaymentSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPaymentPending' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configuredPaymentMethods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'network' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overrideRestriction' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'needsConfirmation' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preferredPaymentProvider' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paymentMethod' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'automatic' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentMethods' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowOverride' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'automatic' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'needsConfirmation' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'paymentProviders' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPaymentSettingsQuery, GetPaymentSettingsQueryVariables>;
export const ReleaseLinkOnboardingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'releaseLinkOnboardingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'shouldDisplayReleaseLinkOnboarding'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReleaseLinkOnboardingStatusQuery,
  ReleaseLinkOnboardingStatusQueryVariables
>;
export const ReleaseLinkOnboardingSeenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'releaseLinkOnboardingSeen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReleaseLinkOnboardingSeenUpdateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseLinkOnboardingSeenUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errorDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'path' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'configs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hasSeenReleaseLinkOnboarding'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ReleaseLinkOnboardingSeenMutation,
  ReleaseLinkOnboardingSeenMutationVariables
>;
export const ReleaseArtistsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReleaseArtists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'releaseId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'release' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'releaseId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headlineArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ReleaseArtistsQuery, ReleaseArtistsQueryVariables>;
export const PromoLabelInvitesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PromoLabelInvites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PromoSubscriptionFilterInput' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'PromoSubscriptionOrderInput' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LabelSubscriptionEnum' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'promoPool' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'labels' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'page' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'page' }
                            }
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'perPage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'perPage' }
                            }
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filterBy' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filterBy' }
                            }
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'orderBy' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'orderBy' }
                            }
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'status' }
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageCount' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodeCount' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasNextPage' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasPreviousPage' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' }
                                  }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addedAt' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasArchiveAccess' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slug' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'size' },
                                              value: { kind: 'EnumValue', value: 'THUMB' }
                                            }
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' }
                                              }
                                            ]
                                          }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'topArtists' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' }
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' }
                                              }
                                            ]
                                          }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'genres' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' }
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' }
                                              }
                                            ]
                                          }
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'beatportFollowerCount'
                                          }
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'soundcloudFollowerCount'
                                          }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<PromoLabelInvitesQuery, PromoLabelInvitesQueryVariables>;
export const GrantArchiveAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'grantArchiveAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveAccessGrant' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' }
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' }
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artistLabelSubscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ArchiveAccessFields' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArchiveAccessFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArtistLabelSubscription' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessExpiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessGrantedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasArchiveAccess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GrantArchiveAccessMutation,
  GrantArchiveAccessMutationVariables
>;
export const RenewArchiveAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'renewArchiveAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveAccessRenew' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' }
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' }
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artistLabelSubscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ArchiveAccessFields' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArchiveAccessFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArtistLabelSubscription' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessExpiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessGrantedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasArchiveAccess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RenewArchiveAccessMutation,
  RenewArchiveAccessMutationVariables
>;
export const RevokeArchiveAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'revokeArchiveAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'artistId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archiveAccessRevoke' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'artistId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'artistId' }
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'labelId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'labelId' }
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'artistLabelSubscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ArchiveAccessFields' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ArchiveAccessFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ArtistLabelSubscription' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessExpiresAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'archiveAccessGrantedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasArchiveAccess' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  RevokeArchiveAccessMutation,
  RevokeArchiveAccessMutationVariables
>;
export const GetLabelSubscribersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLabelSubscribers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LabelSubscriberOrderInput' }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterBy' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LabelSubscriberFilterInput' }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'labelId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscribers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'perPage' }
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterBy' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterBy' }
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nodeCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasNextPage' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastReactedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasArchiveAccess' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'archiveAccessExpiresAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'archiveAccessGrantedAt' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'averageRating' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasPromoAccess' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isFollowing' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slug' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spotifyPopularity' }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLabelSubscribersQuery, GetLabelSubscribersQueryVariables>;
export const GetReleaseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getRelease' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'release' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preorderDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'headlineArtists' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'viewFeedback' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coverArt' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'size' },
                      value: { kind: 'EnumValue', value: 'MEDIUM' }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isLabelManager' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isArtistMember' } }
                    ]
                  }
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ReleaseDetailsFields' }
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MobileButtonsReleaseFields' }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReleaseDetailsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Release' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'genres' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occurrences' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storeInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MobileButtonsReleaseFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Release' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'label' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetReleaseQuery, GetReleaseQueryVariables>;
