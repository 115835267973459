import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Badge, Button } from '@protonradio/proton-ui';
import Avatar from 'components/Avatar';
import Modal from 'components/Modal';
import TextHeader from 'components/TextHeader';

import { BREAKPOINTS, COLORS } from 'config/constants';
import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import { OptInProps } from './OptInModal';
import { StyledModal, StyledTextHeader, StyledLink } from '../styles';
import { DiscoveryModeHistoryState } from '../types';
import { mapDiscoveryModeEntities } from 'routes/DiscoveryMode/helpers';

const OptInSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    max-height: 420px;
  }
`;

const OptInSelectionItem = styled.label<{
  $isDisabled?: boolean;
  $isSelected?: boolean;
  $previouslyOptedIn?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  border-radius: 8px;
  cursor: ${props =>
    props.$previouslyOptedIn || props.$isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => (props.$previouslyOptedIn || props.$isDisabled ? 0.6 : 1)};
  border: 1px solid ${props => (props.$isSelected ? COLORS.PRIMARY : COLORS.BORDER)};
  background-color: ${props =>
    props.$isSelected ? `${COLORS.PRIMARY}10` : 'transparent'};

  > :nth-child(1) {
    flex: 0 0 auto;
    outline: 2px solid ${props => (props.$isSelected ? COLORS.PRIMARY : 'transparent')};
    transition: outline 0.25s ease;
  }
  > :nth-child(2) {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  > :nth-child(3) {
    flex: 0 0 auto;
  }

  transition:
    background-color,
    border-color 0.25s ease;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${COLORS.BORDER};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition:
    background-color 0.25s ease,
    border-color 0.25s ease;

  &:checked {
    background-color: ${COLORS.PRIMARY};
    border-color: ${COLORS.PRIMARY};
  }

  &:focus {
    outline: 2px solid ${COLORS.PRIMARY};
  }

  // check mark
  &:checked::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }
`;

type SelectedEntity = {
  id: string;
  name: string;
  isSelected: boolean;
  type: 'artist' | 'label';
};

export const SelectEntities = ({ viewer, modalLocation }: OptInProps) => {
  const history = useHistory<DiscoveryModeHistoryState>();
  const [selectedEntities, setSelectedEntities] = useState<SelectedEntity[]>([]);
  const [{ fetching }, executeMutation] = useMutationWithAlert(
    ToggleDiscoveryModeMutation
  );

  const entities = mapDiscoveryModeEntities(viewer);

  const optInEntities = async () => {
    const results = await Promise.all(
      selectedEntities.map(selectedEntity => {
        const isEntityArtist = selectedEntity.type === 'artist';

        return executeMutation({
          ...(isEntityArtist
            ? { artistId: selectedEntity.id }
            : { labelId: selectedEntity.id }),
          enabled: true
        });
      })
    );

    if (results.some(result => result.hasErrors)) {
      return;
    }

    history.replace({
      pathname: '/dm-opt-in/success',
      search: history.location.search,
      state: { modal: true, modalLocation, step: 'success' }
    });
  };

  return (
    <Modal
      testId="DiscoveryMode-EntitySelection-Modal"
      onClose={() =>
        history.replace({ pathname: modalLocation, search: history.location.search })
      }
      title="Opt In Selection"
    >
      <StyledModal>
        <form
          style={{ width: '95%' }}
          onSubmit={e => {
            e.preventDefault();
            optInEntities();
          }}
        >
          <TextHeader type={TextHeader.TYPES.XSMALL}>Artists</TextHeader>
          <OptInSelectionContainer
            role="listbox"
            aria-label="Opt In Selection"
            aria-orientation="vertical"
          >
            {entities.map(entity => {
              const isPreviouslyOptedIn = entity.isOptedIn;
              const isSelected = selectedEntities.some(
                selected => selected.id === entity.id
              );

              return (
                <OptInSelectionItem
                  key={entity.id}
                  $isSelected={isSelected || isPreviouslyOptedIn}
                  $previouslyOptedIn={isPreviouslyOptedIn}
                >
                  <Avatar
                    imageUrl={entity.imageUrl}
                    size={Avatar.SIZES.LARGE}
                    circle
                    altTag={entity.name}
                  />

                  <div>
                    <StyledTextHeader type={TextHeader.TYPES.LARGE}>
                      {entity.name}
                    </StyledTextHeader>

                    {isPreviouslyOptedIn && <Badge variant="success">Opted in</Badge>}
                  </div>

                  <StyledCheckbox
                    type="checkbox"
                    name="artist"
                    value={entity.id}
                    checked={isSelected || isPreviouslyOptedIn}
                    onChange={e => {
                      const isChecked = e.target.checked;
                      setSelectedEntities(prev => {
                        if (isChecked) {
                          return [
                            ...prev,
                            {
                              id: entity.id,
                              name: entity.name || '',
                              isSelected: true,
                              type: entity.type
                            }
                          ];
                        }
                        return prev.filter(selected => selected.id !== entity.id);
                      });
                    }}
                  />
                </OptInSelectionItem>
              );
            })}
          </OptInSelectionContainer>
          <Modal.ButtonGroup justify="center">
            <Button variant="secondary">
              <StyledLink
                to={{
                  pathname: '/dm-opt-in',
                  search: history.location.search,
                  state: {
                    modal: true,
                    modalLocation,
                    step: 'dm-opt-in'
                  }
                }}
              >
                Back
              </StyledLink>
            </Button>
            <Button
              type="submit"
              variant="primary"
              onPress={() => optInEntities()}
              isDisabled={selectedEntities.length === 0 || fetching}
            >
              {fetching ? `Opting in...` : 'Opt in'}
            </Button>
          </Modal.ButtonGroup>
        </form>
      </StyledModal>
    </Modal>
  );
};
